import React, { useState, useEffect } from 'react';
import Select from 'components/ui/Select'
import { useParams } from 'react-router-dom';
import TermsOfService from './legal/TermsOfService.js'
import PrivacyPolicy from './legal/PrivacyPolicy.js'
import NoticeofCollectionofInformation from './legal/NoticeofCollectionofInformation.js'
import NoticeofRightToOptOutofSaleofInformation from './legal/NoticeofRightToOptOutofSaleofInformation.js'
import DataSubjectsRequestForm from './legal/DataSubjectsRequestForm.js'

const POLICY_DATA = {
  "Terms of Service": <TermsOfService />,
  "Privacy Policy": <PrivacyPolicy />,
  "Notice of Collection of Information": <NoticeofCollectionofInformation />,
  "Notice of Right to Opt Out of Sale of Information": <NoticeofRightToOptOutofSaleofInformation />,
  "Data Subjects Request Form": <DataSubjectsRequestForm  />
};

export default function Legal() {
  const { item } = useParams();
  const [selectState, setSelectState] = useState(item || 'Terms of Service');
  const selectList = ["Terms of Service", "Privacy Policy","Notice of Collection of Information","Notice of Right to Opt Out of Sale of Information", "Data Subjects Request Form"];
  const id = "policy";

  useEffect(() => {
    setSelectState(item || 'Terms of Service');
  }, [item]);

  useEffect(() => {
    switch (selectState) {
      case "TermsofService":
        setSelectState("Terms of Service");
        break;
      case "PrivacyPolicy":
        setSelectState('Privacy Policy');
        break;
      case "NoticeofCollectionofInformation":
        setSelectState("Notice of Collection of Information");
        break;
      case "NoticeofRighttoOptOutofSaleofInformation":
        setSelectState('Notice of Right to Opt Out of Sale of Information');
        break;
      case "DataSubjectsRequestForm":
        setSelectState('Data Subjects Request Form');
        break;
      default:
        break;
    }
  }, [selectState]);

  const data = { selectState, selectList, id };

  return (
    <div className="info-container">
      <div className="info-inner">
        <div className="content-layout">
        <div className="content-layout__head">
          <Select {...data} setSelectState={setSelectState} />
        </div>
          <div className="content-layout__inner">
            {POLICY_DATA[selectState]}
          </div>
        </div>
      </div>
    </div>
  )
}