import Dialog from '@mui/material/Dialog';
import { forwardRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import Slide from '@mui/material/Slide';
import { Link } from 'react-router-dom';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const makePlatformList = (desc) => {
  if (!desc.platformList || (desc.platformList.length > 0 && typeof desc.platformList[0] === "string") ) {
    let list = desc.platform.split(",")
    list = list.map(l=>{
      return {name:l, link: ""}
    })
    desc.platformList = list
  }
}

function MobileModal(props) {
  const { open, handleClose, data } = props;
  const { t } = useTranslation()
  makePlatformList(data.desc)
  return (
    <div>
      <Dialog
        fullScreen
        TransitionComponent={Transition}
        open={open}
        onClose={handleClose}
        className="mobile-dialog-wrapper"
        componentsProps={{ backdrop: { style: { backgroundColor: "rgba(0, 0, 0, 0.8)" } } }}
      >
        <button onClick={handleClose} className="close-btn"></button>
        <div className="mobile-dialog-container">
          <div className="mobile-dialog-container__header">
          </div>
          <div className="mobile-dialog-image">
            <img src={data.image} alt={`${data.title} thumbnail img`} />
          </div>
          <div className="mobile-dialog-inner">
            <div className="detail-info">
              <div className="detail-info__head">
                <div className="detail-info__head--title">
                  <div className="title-desc">
                    <p className={`${data.desc.ageRating === '19세 이용가' ? 'age19' : ''}`}>{data.title}</p>
                  </div>
                  <span className="sub-desc">{t("webtoons.detail.originalWork")}: {data.desc.author}</span>
                </div>
                <ul className="detail-info__head--list">
                  {data.desc.platformList.length === 1 ?
                    <li>
                      <span className="sub-desc">{t("webtoons.detail.platform")}: {data.desc.platformList[0].name}</span>
                      {data.desc.platformList[0].link?<Link className="link-btn" to={data.desc.platformList[0].link} target='_blink'>{t("webtoons.detail.goPlatform")}</Link>:<></>}
                    </li>
                  :
                    <>
                      <li><span className="sub-desc">{t("webtoons.detail.platform")}</span></li>
                      <li>
                        <ul>
                          {data.desc.platformList.map((item, index) => {
                            return <li key={index}>
                              <span className="sub-desc"> • {item.name}</span>
                              {item.link?<Link className="link-btn" to={item.link} target='_blink'>{t("webtoons.detail.goPlatform")}</Link>:<></>}
                            </li>
                          })}
                        </ul>
                      </li>
                    </>
                  }
                  <li><span className="sub-desc">{t("webtoons.detail.serialization")}: {data.desc.serial}</span></li>
                  <li><span className="sub-desc">{t("webtoons.detail.genre")}: {data.desc.genre}</span></li>
                  <li><span className="sub-desc">{t("webtoons.detail.ageText")}: {data.desc.ageRating}</span></li>
                </ul>
              </div>
              <div className="detail-info__body">
                <p className="body-title">{t("webtoons.detail.synopsis")}</p>
                <p className="sub-desc">{data.desc.synopsis}</p>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

function DefaultModal(props) {
  const { open, handleClose, data } = props;
  const { t } = useTranslation()
  makePlatformList(data.desc)
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        className="base-dialog-wrapper grid-dialog-wrapper"
      >
        <div className="base-dialog-container">
          <button onClick={handleClose} className="close-btn"></button>
          <div className="base-dialog-image">
            <img src={data.image} alt={`${data.title} thumbnail img`} />
          </div>
          <div className="base-dialog-inner">
            <div className="detail-info">
              <div className="detail-info__head">
                <div className="detail-info__head--title">
                  <div className="title-desc">
                    <p className={`${data.desc.ageRating === '19세 이용가' ? 'age19' : ''}`}>{data.title}</p>
                  </div>
                  <span className="sub-desc">{t("webtoons.detail.originalWork")}: {data.desc.author}</span>
                </div>
                <ul className="detail-info__head--list">
                  {data.desc.platformList.length === 1 ?
                    <li>
                      <span className="sub-desc">{t("webtoons.detail.platform")}: {data.desc.platformList[0].name}</span>
                      {data.desc.platformList[0].link?<Link className="link-btn" to={data.desc.platformList[0].link} target='_blink'>{t("webtoons.detail.goPlatform")}</Link>:<></>}
                    </li>
                  :
                  <>
                    <li><span className="sub-desc">{t("webtoons.detail.platform")}</span></li>
                    <li>
                      <ul>
                        {data.desc.platformList.map((item, index) => {
                          return <li key={index}>
                            <span className="sub-desc"> • {item.name}</span>
                            {item.link?<Link className="link-btn" to={item.link} target='_blink'>{t("webtoons.detail.goPlatform")}</Link>:<></>}
                          </li>
                        })}
                      </ul>
                    </li>
                  </>
                  }
                  <li><span className="sub-desc">{t("webtoons.detail.serialization")}: {data.desc.serial}</span></li>
                  {data.desc.genre && <li><span className="sub-desc">{t("webtoons.detail.genre")}: {data.desc.genre}</span></li>}
                  <li><span className="sub-desc">{t("webtoons.detail.ageText")}: {data.desc.ageRating}</span></li>
                </ul>
              </div>
              <div className="detail-info__body">
                <p className="body-title">{t("webtoons.detail.synopsis")}</p>
                <p className="sub-desc">{data.desc.synopsis}</p>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default function WebtoonDetailModal(props) {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
    {isWideScreen ? <MobileModal {...props} /> : <DefaultModal {...props} />}
    </>
  );
}