import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import store from "utils/store";
import { useTranslation } from 'react-i18next'
import dompurify from "dompurify";
import BannerLayout from "components/ui/banner/BannerLayout"
import Bg from "assets/Content/banner/pages/dramas.png";
import MobileBg from "assets/Content/banner/pages/dramas-m.png";

const tabs = [
  {
    id: 'all',
    name: 'All',
  },
]

export function TabsLayout() {
  const [data, setData] = useState([]);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const lang = useSelector(state=>{
    return state.user?.language;
  });
  useEffect(() => {
    const loacle = lang !== 'ko' && lang ? `_${lang}` : '';
    const content_id = '드라마'+ loacle;
    store.getBoardsByOrder(content_id, "DESC")
    .then(data => {
      let list = []
      data.forEach(element => {
        list.push({
          image: store.awsFilePath(element.thumbnail),
          title: element.title,
          desc: JSON.parse(element.body),
        })
      });
      setData(list)
    })
  }, []);
  return (
    <div className="tabs-layout">
      <nav className="tab-header">
        <div className="tab-header__inner">
          { tabs.map((tab, index) => {
            return <div className={`tab-header-item ${selectedTab === tab ? 'isActive' : ''}`}
            key={index} onClick={() => setSelectedTab(tab)}><p>{tab.name}</p></div>
          }) }
        </div>
      </nav>
      <div className="tab-body dramas">
        {data.map((item, index) => {
        return <div className="dramas-item" key={index}>
          <div className="dramas-item__inner">
            <img className="thumbnail" src={item.image} alt={item.title} />
            <div className="info-text">
              <p className="name">{item.title}</p>
              <span className="type">{item.desc.genre}</span>
            </div>
          </div>
        </div>
      })}
      </div>
    </div>
  );
}


export default function Dramas() {
  const { t } = useTranslation()
  const sanitizer = dompurify.sanitize;
  const bannerInfo = {
    title: 'drama.mainBannerTitle',
    background: Bg,
    backgroundMobile: MobileBg,
    textColor: 'white'
  }
  return (
    <div className="dramas-container">
      <BannerLayout {...bannerInfo} />
      <div className="dramas-info">
        <div className="dramas-info__inner">
          <p className="information-desc" dangerouslySetInnerHTML={{ __html: sanitizer(t("drama.information")) }}></p>
        </div>
      </div>
      <div className="dramas-inner">
        <TabsLayout />
      </div>
    </div>
  )
}