/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getGhostBoardList = /* GraphQL */ `
  query GetGhostBoardList($id: ID!) {
    getGhostBoardList(id: $id) {
      id
      GhostBoardList
      order
      GhostBoards {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGhostBoardLists = /* GraphQL */ `
  query ListGhostBoardLists(
    $filter: ModelGhostBoardListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGhostBoardLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        GhostBoardList
        order
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const BoardsByOrder = /* GraphQL */ `
  query BoardsByOrder(
    $GhostBoardList: String!
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGhostBoardListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    BoardsByOrder(
      GhostBoardList: $GhostBoardList
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        GhostBoardList
        order
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGhostBoard = /* GraphQL */ `
  query GetGhostBoard($id: ID!) {
    getGhostBoard(id: $id) {
      id
      is_public
      create_date
      body
      ghostboardlistID
      title
      thumbnail
      order
      file
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGhostBoards = /* GraphQL */ `
  query ListGhostBoards(
    $filter: ModelGhostBoardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGhostBoards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        is_public
        create_date
        body
        ghostboardlistID
        title
        thumbnail
        order
        file
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ghostBoardsByGhostboardlistID = /* GraphQL */ `
  query GhostBoardsByGhostboardlistID(
    $ghostboardlistID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGhostBoardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ghostBoardsByGhostboardlistID(
      ghostboardlistID: $ghostboardlistID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        is_public
        create_date
        body
        ghostboardlistID
        title
        thumbnail
        order
        file
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ByTitle = /* GraphQL */ `
  query ByTitle(
    $ghostboardlistID: ID!
    $title: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGhostBoardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ByTitle(
      ghostboardlistID: $ghostboardlistID
      title: $title
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        is_public
        create_date
        body
        ghostboardlistID
        title
        thumbnail
        order
        file
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ByOrder = /* GraphQL */ `
  query ByOrder(
    $ghostboardlistID: ID!
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGhostBoardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ByOrder(
      ghostboardlistID: $ghostboardlistID
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        is_public
        create_date
        body
        ghostboardlistID
        title
        thumbnail
        order
        file
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ByCreateDate = /* GraphQL */ `
  query ByCreateDate(
    $ghostboardlistID: ID!
    $create_date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGhostBoardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ByCreateDate(
      ghostboardlistID: $ghostboardlistID
      create_date: $create_date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        is_public
        create_date
        body
        ghostboardlistID
        title
        thumbnail
        order
        file
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
