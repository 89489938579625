
import { useTranslation } from 'react-i18next'
import dompurify from "dompurify";

export default function BannerLayout(props) {
  const { title, background, backgroundMobile, titleImage, textColor, size } = props;
  const { t } = useTranslation()
  const sanitizer = dompurify.sanitize;
  return (
    <section className={`banner-layout ${size && size}`}>
      <img className="background" src={background}/>
      <img className="backgroundMobile" src={backgroundMobile}/>
      <div className="banner-layout-inner">
        { title ?
        (<p className={`banner-title-text ${textColor}`}
         dangerouslySetInnerHTML={{ __html: sanitizer(t(title)) }}></p>) :
        titleImage ?
        (<img className="banner-title-image" src={titleImage}></img>) :
        ''
        }
      </div>
    </section>
  )
}