import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import store from "utils/store";
import WebtoonDetailModal from "components/modal/WebtoonDetail";
import BannerLayout from "components/ui/banner/BannerLayout"
import Bg from "assets/Content/banner/pages/webtoons.png";
import MobileBg from "assets/Content/banner/pages/webtoons-m.png";

const tabs = [
  {
    id: 'all',
    name: 'All',
  },
  {
    id: 'webtoon',
    name: 'Webtoon',
  },
  {
    id: 'webnovel',
    name: 'Webnovel',
  },
]

export function TabsLayout() {
  const [data, setData] = useState([]);
  const [detailOpen, setDetailOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [selectedItem, setSelectedItem] = useState(null);
  const lang = useSelector(state=>{
    return state.user?.language;
  });
  useEffect(() => {
    const loacle = lang !== 'ko' && lang ? `_${lang}` : '';
    const content_id = '웹툰'+ loacle;
    store.getBoardsByOrder(content_id, "DESC")
    .then(data => {
      let list = []
      data.forEach(element => {
        list.push({
          id: element.id,
          image: store.awsFilePath(element.thumbnail),
          title: element.title,
          date: new Date(element.create_date).toLocaleDateString().replace(/\./g, '').replace(/\s/g, '-'),
          desc: JSON.parse(element.body),
        })
      });
      setData(list)
    })
  }, [lang]);

  const detailsModalOpen = (item) => {
    setSelectedItem(item);
    setDetailOpen(true);
  };
  const detailsModalClose = () => {
    setDetailOpen(false)
  };

  return (
    <div>
      <div className="tabs-layout">
        <nav className="tab-header">
          <div className="tab-header__inner">
            { tabs.map((tab, index) => {
              return <div className={`tab-header-item ${selectedTab === tab ? 'isActive' : ''}`}
              key={index} onClick={() => setSelectedTab(tab)}><p>{tab.name}</p></div>
            }) }
          </div>
        </nav>
        <div className="tab-body webtoon">
          { data.map((item, index) => {
          if (item.desc.category.indexOf(selectedTab.name) >= 0 || selectedTab.id === 'all') {
            return <div className="webtoon-item" key={index}>
              <div className="webtoon-item__inner" onClick={() => detailsModalOpen(item)}>
                <img className="thumbnail" src={item.image} alt={item.title} />
                <div className="info-text">
                  <p className="name">{item.title}</p>
                  <span className="type">{item.desc.category}</span>
                </div>
              </div>
            </div>
          }
          return null;
          })}
        </div>
      </div>
      {detailOpen &&
        <WebtoonDetailModal open={detailOpen} handleClose={detailsModalClose} data={selectedItem} />
      }
    </div>
  );
}



export default function Webtoons() {
  const bannerInfo = {
    title: 'webtoons.mainBannerTitle',
    background: Bg,
    backgroundMobile: MobileBg,
    textColor: 'white'
  }
  return (
    <div className="webtoons-container">
      <BannerLayout {...bannerInfo} />
      <div className="webtoons-inner">
        <TabsLayout />
      </div>
    </div>
  )
}