import React from 'react';

class ExternalHTMLRenderer extends React.Component {
  constructor(props) {
    super(props);
    this.externalHTML = `<div class="wrap">
    <div class="disTit tal">
      <h1>Notice of Collection of Information</h1>
    </div>
    <div class="artContent mt40">

<p style=";text-align: justify;font-size: 14px;font-family: DengXian;white-space: normal"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;"><strong>Notice of Collection of Information</strong></span></p>
<p style=";text-align: justify;font-size: 14px;font-family: DengXian;white-space: normal"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;">(FOR CALIFORNIA RESIDENTS ONLY)</span></p>
<p style=";text-align: justify;font-size: 14px;font-family: DengXian;white-space: normal"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;">Lastly Updated Oct 16, 2023</span></p>
<p style=";text-align: justify;font-size: 14px;font-family: DengXian;white-space: normal"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;">&nbsp;</span></p>
<p style=";text-align: justify;font-size: 14px;font-family: DengXian;white-space: normal"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;">If you are a California resident, starting January 1, 2020, you have a right to notice of our collection of consumer personal information, as defined in the California Consumer Privacy Act of 2018, Civil Code §1798.100 et seq. (CCPA).&nbsp;This Notice is to provide you with information regarding our collection of consumer personal information. The categories of personal information that we collect and the description of each of those categories are set forth in the table below.&nbsp;</span></p>
<p><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;"></span></p>
<table><tbody>
<tr class="firstRow">
<td valign="top" style="border: 2px double windowtext; padding: 6px 10px;" width="186" class="selectTdClass"><p style=";font-size: 14px;font-family: DengXian;line-height: 22px"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;"><strong><span style="font-family: arial, helvetica, sans-serif; background-color: white;">Categories of Personal Information</span></strong></span></p></td>
<td valign="top" style="border-top: 2px double windowtext; border-right: 2px double windowtext; border-bottom: 2px double windowtext; border-left-style: none; padding: 6px 10px; border-left-color: windowtext;" width="186" class="selectTdClass"><p style=";font-size: 14px;font-family: DengXian;line-height: 22px"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;"><strong><span style="font-family: arial, helvetica, sans-serif; background-color: white;">Categories of sources from which information is collected:</span></strong></span></p></td>
<td valign="top" style="border-top: 2px double windowtext; border-right: 2px double windowtext; border-bottom: 2px double windowtext; border-left-style: none; padding: 6px 10px; border-left-color: windowtext;" width="186" class="selectTdClass"><p style=";font-size: 14px;font-family: DengXian;line-height: 22px"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;"><strong><span style="font-family: arial, helvetica, sans-serif; background-color: white;">Business or commercial purposes for collection, use and sharing:</span></strong></span></p></td>
<td valign="top" style="border-top: 2px double windowtext; border-right: 2px double windowtext; border-bottom: 2px double windowtext; border-left-style: none; padding: 6px 10px; border-left-color: windowtext;" width="186" class="selectTdClass"><p style=";font-size: 14px;font-family: DengXian;line-height: 22px"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;"><strong><span style="font-family: arial, helvetica, sans-serif; background-color: white;">Disclosed for business purposes to the following categories of third parties:</span></strong></span></p></td>
<td valign="top" style="border-top: 2px double windowtext; border-right: 2px double windowtext; border-bottom: 2px double windowtext; border-left-style: none; padding: 6px 10px; border-left-color: windowtext;" width="186" class="selectTdClass"><p style=";font-size: 14px;font-family: DengXian;line-height: 22px"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;"><strong><span style="font-family: arial, helvetica, sans-serif; background-color: white;">Sold to the following categories of third parties:</span></strong></span></p></td>
</tr>
<tr>
<td valign="top" style="border-right: 2px double windowtext; border-bottom: 2px double windowtext; border-left: 2px double windowtext; border-top-style: none; padding: 6px 10px; border-top-color: windowtext;" width="186" class="selectTdClass">
<p style=";font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Personal and online identifiers (such as unique online identifiers, IP address)</span></p>
<p style=";font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">&nbsp;</span></p>
</td>
<td valign="top" style="border-style: none double double none; border-bottom-width: 2px; border-color: windowtext; border-right-width: 2px; padding: 6px 10px;" width="186" class="selectTdClass">
<p style=";font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Consumers</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">&nbsp;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">&nbsp;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">&nbsp;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">&nbsp;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">&nbsp;</span></p>
</td>
<td valign="top" style="border-style: none double double none; border-bottom-width: 2px; border-color: windowtext; border-right-width: 2px; padding: 6px 10px;" width="186" class="selectTdClass">
<p style=";font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Marketing;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Advertising;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Authentication;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Identity resolution;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Fraud detection;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Fulfillment services; Facilitating transactions;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Auditing related to our interactions with you;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Legal compliance;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Detecting and protecting against security incidents, fraud, and illegal activity;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Debugging;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Performing services (for us or our service providers) such as account servicing, processing orders and payments, and analytics;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Internal research for technological improvement;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Internal operations;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Activities to maintain and improve our services; and</span></p>
<p style=";font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Other one-time uses</span></p>
</td>
<td valign="top" style="border-style: none double double none; border-bottom-width: 2px; border-color: windowtext; border-right-width: 2px; padding: 6px 10px;" width="186" class="selectTdClass">
<p style=";font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Affiliates;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Service providers; and</span></p>
<p style=";font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Government agencies</span></p>
</td>
<td valign="top" style="border-style: none double double none; border-bottom-width: 2px; border-color: windowtext; border-right-width: 2px; padding: 6px 10px;" width="186" class="selectTdClass"><p style=";font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Advertising/marketing companies;<br />Advertising networks;<br />Social networks</span></p></td>
</tr>
<tr style="height: 484px">
<td valign="top" style="border-right: 2px double windowtext; border-bottom: 2px double windowtext; border-left: 2px double windowtext; border-top-style: none; padding: 6px 10px; border-top-color: windowtext;" width="186" class="selectTdClass">
<p style=";font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Internet or other electronic network activity information (such as interactions with an application, or advertisement)</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">&nbsp;</span></p>
</td>
<td valign="top" style="border-style: none double double none; border-bottom-width: 2px; border-color: windowtext; border-right-width: 2px; padding: 6px 10px;" width="186" class="selectTdClass">
<p style=";font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Consumers;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Service Providers</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">&nbsp;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">&nbsp;</span></p>
</td>
<td valign="top" style="border-style: none double double none; border-bottom-width: 2px; border-color: windowtext; border-right-width: 2px; padding: 6px 10px;" width="186" class="selectTdClass">
<p style=";font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Marketing;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Advertising;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Authentication;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Identity resolution;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Fraud prevention;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Fulfillment services; Facilitating transactions;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Auditing related to our interactions with you;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Legal compliance;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Detecting and protecting against security incidents, fraud, and illegal activity; Performing services (for us or our service provider) such as account servicing, processing orders and payments, and analytics;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Internal research for technological improvement;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Internal operations;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Activities to maintain and improve our services; and</span></p>
<p style=";font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Other one-time uses</span></p>
</td>
<td valign="top" style="border-style: none double double none; border-bottom-width: 2px; border-color: windowtext; border-right-width: 2px; padding: 6px 10px;" width="186" class="selectTdClass">
<p style=";font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Affiliates;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Service providers</span></p>
</td>
<td valign="top" style="border-style: none double double none; border-bottom-width: 2px; border-color: windowtext; border-right-width: 2px; padding: 6px 10px;" width="186" class="selectTdClass"><p style=";font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Advertising/marketing companies;<br />Advertising networks;<br />Social networks</span></p></td>
</tr>
<tr>
<td valign="top" style="border-right: 2px double windowtext; border-bottom: 2px double windowtext; border-left: 2px double windowtext; border-top-style: none; padding: 6px 10px; border-top-color: windowtext;" width="186" class="selectTdClass"><p style=";font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Commercial or transactions information (such as records of personal property or products or services purchased, obtained or considered; purchasing or consuming histories or tendencies)</span></p></td>
<td valign="top" style="border-style: none double double none; border-bottom-width: 2px; border-color: windowtext; border-right-width: 2px; padding: 6px 10px;" width="186" class="selectTdClass">
<p style=";font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Consumers;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Service Providers;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">App store providers</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">&nbsp;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">&nbsp;</span></p>
</td>
<td valign="top" style="border-style: none double double none; border-bottom-width: 2px; border-color: windowtext; border-right-width: 2px; padding: 6px 10px;" width="186" class="selectTdClass">
<p style=";font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Fulfillment services; Facilitating transactions;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Auditing related to our interactions with you;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Debugging;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Performing services (for us or our service provider) such as account servicing, processing orders and payments, and analytics;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Internal research for technological improvement;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Internal operations;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Activities to maintain and improve our services</span></p>
<p style=";font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Other one-time uses</span></p>
</td>
<td valign="top" style="border-style: none double double none; border-bottom-width: 2px; border-color: windowtext; border-right-width: 2px; padding: 6px 10px;" width="186" class="selectTdClass"><p style=";font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Affiliates;<br />Service providers; App store providers</span></p></td>
<td valign="top" style="border-style: none double double none; border-bottom-width: 2px; border-color: windowtext; border-right-width: 2px; padding: 6px 10px;" width="186" class="selectTdClass">
<p style=";font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">None</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">&nbsp;</span></p>
</td>
</tr>
<tr>
<td valign="top" style="border-right: 2px double windowtext; border-bottom: 2px double windowtext; border-left: 2px double windowtext; border-top-style: none; padding: 6px 10px; border-top-color: windowtext;" width="186" class="selectTdClass">
<p style=";font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Geolocation information</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">&nbsp;</span></p>
</td>
<td valign="top" style="border-style: none double double none; border-bottom-width: 2px; border-color: windowtext; border-right-width: 2px; padding: 6px 10px;" width="186" class="selectTdClass">
<p style=";font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Service Providers</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">&nbsp;</span></p>
</td>
<td valign="top" style="border-style: none double double none; border-bottom-width: 2px; border-color: windowtext; border-right-width: 2px; padding: 6px 10px;" width="186" class="selectTdClass">
<p style=";font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Marketing;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Advertising;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Fulfillment services; Facilitating transactions;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Auditing related to our interactions with you;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Legal compliance;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Debugging;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Performing services (for us or our service provider) such as account servicing, processing orders and payments, and analytics;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Internal research for technological improvement;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Internal operations;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Activities to maintain and improve our services; and</span></p>
<p style=";font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Other one-time uses</span></p>
</td>
<td valign="top" style="border-style: none double double none; border-bottom-width: 2px; border-color: windowtext; border-right-width: 2px; padding: 6px 10px;" width="186" class="selectTdClass"><p style=";font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Affiliates;<br />Service providers</span></p></td>
<td valign="top" style="border-style: none double double none; border-bottom-width: 2px; border-color: windowtext; border-right-width: 2px; padding: 6px 10px;" width="186" class="selectTdClass"><p style=";font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Advertising/marketing companies;<br />Advertising networks;<br />Data analytics providers; and<br />Social networks</span></p></td>
</tr>
<tr>
<td valign="top" style="border-right: 2px double windowtext; border-bottom: 2px double windowtext; border-left: 2px double windowtext; border-top-style: none; padding: 6px 10px; border-top-color: windowtext;" width="186" class="selectTdClass">
<p style=";font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Inferences drawn from the above information about your predicted characteristics and preferences</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">&nbsp;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">&nbsp;</span></p>
</td>
<td valign="top" style="border-style: none double double none; border-bottom-width: 2px; border-color: windowtext; border-right-width: 2px; padding: 6px 10px;" width="186" class="selectTdClass"><p style=";font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Consumers</span></p></td>
<td valign="top" style="border-style: none double double none; border-bottom-width: 2px; border-color: windowtext; border-right-width: 2px; padding: 6px 10px;" width="186" class="selectTdClass">
<p style=";font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Marketing;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Advertising;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Legal compliance;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Detecting and protecting against security incidents, fraud, and illegal activity;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Debugging;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Performing services (for us or our service provider) such as account servicing, processing orders and payments, and analytics;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Internal research for technological improvement;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Internal operations;</span></p>
<p style="margin: 0 0 8px;font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Activities to maintain and improve our services; and</span></p>
<p style=";font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Other one-time uses</span></p>
</td>
<td valign="top" style="border-style: none double double none; border-bottom-width: 2px; border-color: windowtext; border-right-width: 2px; padding: 6px 10px;" width="186" class="selectTdClass"><p style=";font-size: 14px;font-family: DengXian;line-height: 22px"><span style="background-color: white; font-size: 14px; font-family: arial, helvetica, sans-serif;">Affiliates</span></p></td>
<td valign="top" style="border-style: none double double none; border-bottom-width: 2px; border-color: windowtext; border-right-width: 2px; padding: 6px 10px;" width="186" class="selectTdClass"><br /></td>
</tr>
</tbody></table>
<p><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;"><br /></span></p>
<p><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;">To learn more about how we collect and use your information, please see our&nbsp;</span><a href='PrivacyPolicy' target="_self" style="text-decoration: underline; font-family: arial, helvetica, sans-serif; font-size: 14px; color: rgb(0, 0, 0);"><strong><span style="font-family: arial, helvetica, sans-serif; font-size: 14px; color: rgb(0, 0, 0);">Privacy Policy</span></strong></a><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;">. If you want to opt-out our sales of your information, please visit&nbsp;<a href="NoticeofRighttoOptOutofSaleofInformation" target="_self"><strong><span style="font-family: arial, helvetica, sans-serif; font-size: 14px; text-decoration: underline; color: rgb(0, 0, 0);">Notice of Right to Opt Out of Sale of Information</span></strong></a><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;">.</span></span></p>
    </div>
  </div>
    `
  }

  render() {
    return <div dangerouslySetInnerHTML={{ __html: this.externalHTML }} />;
  }
}

export default ExternalHTMLRenderer;