import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import Logo from 'assets/Logo/logo_invert.png';
import { useTranslation } from 'react-i18next'
import { GNB_ITEMS, SNS_LIST } from 'constants/gnbList';

export default function Footer() {
  const navigate = useNavigate();
  const { t } = useTranslation()
  const userLanguage = useSelector(state => {
    return state.user.language;
  });
  return (
    <footer className="footer-wrapper">
      <div className="footer-inner">
        <div className="top-container">
          <div className="company-info">
            <img className="logo-btn" onClick={() => navigate(`/${userLanguage}`)} src={Logo} alt="ghost studio logo" />
            <div className="company-info__desc">
              <p>{t("footer.companyInfo")}</p>
              <p>{t("footer.copyright")}</p>
            </div>
          </div>
          <ul className="gnb-container">
            {GNB_ITEMS.map((item, index) => {
              if (
                !(userLanguage === 'ko' && item.name === 'recruit')
              ) {
                return <li key={index} className={`gnb-item ${item.name}`}>
                  <p className="gnb-item-path" onClick={() => navigate(`/${userLanguage}/${item.pathName}`)}>{t(`header.${[item.name]}`)}</p>
                  <ul key={index} className="children-container">
                    {item.children.map((itm, idx) => {
                      if (
                        !(userLanguage !== 'ko' && itm.name === 'irNav.announcements')
                      ) {
                        return <li className="children-item" key={idx}>
                        <p className="children-item-path" onClick={() => navigate(`/${userLanguage}/${itm.pathName}`)}>{t(`header.${[itm.name]}`)}</p></li>
                      }
                    })}
                  </ul>
                </li>
              }
            })}
          </ul>
        </div>
        <div className="bottom-container">
          <ul className="info-link">
            <li><p onClick={() => navigate(`/${userLanguage}/guideReporting`)}>Guide to Reporting Ethics Violations</p></li>
            <li><p onClick={() => navigate(`/${userLanguage}/legal/TermsofService`)}>Terms of Service</p></li>
            <li><p onClick={() => navigate(`/${userLanguage}/legal/PrivacyPolicy`)}>Privacy Policy</p></li>
            <li><p onClick={() => navigate(`/${userLanguage}/legal/NoticeofCollectionofInformation`)}>Notice of Collection of Information</p></li>
            <li><p onClick={() => navigate(`/${userLanguage}/legal/NoticeofRighttoOptOutofSaleofInformation`)}>Notice of Right to Opt Out of Sale of Information</p></li>
            <li><p onClick={() => navigate(`/${userLanguage}/legal/DataSubjectsRequestForm`)}>Data Subjects Request Form</p></li>
          </ul>
          <ul className="sns-list">
            {SNS_LIST.map((item, index) => {
              return <li onClick={() => {window.open(item.href)}} className={item.name} key={index}></li>
            })}
          </ul>
        </div>
      </div>
    </footer>
  )
}