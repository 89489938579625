

export default function GuideReporting() {
  return (
    <div className="info-container">
      <div className="info-inner">
        <div className="content-layout">
          <div className="content-layout__inner">
            <p className="content-layout__inner--title">Guide to Reporting Ethics Violations</p>
            <ul className="content-layout__inner--body">
              <li>GHOST STUDIO operates an ethics violation reporting channel to create a healthy corporate climate.<br /> If you report any corruption related to the job of an employee you have encountered, we will take active action and thoroughly guarantee your identity.</li>
              <li>Report any existing or potential corruption, fraudulent activities and non-compliance: ethic@ghoststudio.net</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}




