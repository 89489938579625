import React from 'react';
import ReactDOM from 'react-dom/client';
import App from "./App";
import "./locales/i18n";
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from 'store/configureStore';
import 'styles/style.scss';
import ScrollToTop from './components/common/ScrollToTop';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </BrowserRouter>
  </Provider>
);