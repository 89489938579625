import { Navigate, Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GET_LANGUAGE } from 'store/modules/user';
import Layout from "./Layout";
import Main from "./pages/Main";
import GhostStudio from "./pages/GhostStudio";
// import Audition from "./pages/Audition";
import Games from "./pages/Games";
import GamesDetail from "./pages/GamesDetail";
import Webtoons from "./pages/Webtoons";
import Artist from "./pages/Artist";
import Dramas from "./pages/Dramas";
import Financial from "./pages/Financial"; // 재무정보
import Disclosure from "./pages/Disclosure"; // 공시정보
import Announcements from "./pages/Announcements"; // 공고
import IrDetail from 'pages/IrDetail';
import News from "./pages/News";
import NewsDetail from 'pages/NewsDetail';
import Recruit from "./pages/Recruit";
import RecruitDetail from 'pages/RecruitDetail';
import Stock from "./pages/Stock";
import ErrorPage from "./pages/Error";
import GuideReporting from "./pages/GuideReporting";
import Legal from "./pages/Legal";
import ReferenceRoom from "./pages/ReferenceRoom";


function App() {
  const dispatch = useDispatch();
  const userLanguage = useSelector(state => {
    return state?.user?.language;
  });
  useEffect(() => {
    dispatch(GET_LANGUAGE());
  }, [userLanguage, dispatch]);

  return (
    <Routes>
      {<Route path="/" element={<Navigate replace to={`/${userLanguage}`} />} />}
      <Route path="/:language" element={<Layout />}>
        <Route index element={<Main />} />
        <Route path="ghoststudio" element={<GhostStudio />} />
        <Route path="games" element={<Games />} />
        <Route path="games/:id" element={<GamesDetail state="games" />} />
        <Route path="webtoons" element={<Webtoons />} />
        <Route path="dramas" element={<Dramas />} />
        <Route path="artists" element={<Artist />} />
        <Route path="disclosure" element={<Disclosure />} />
        <Route path="announcements" element={<Announcements />} />
        <Route path="announcements/:index" element={<IrDetail state="announcements" />} />
        <Route path="referenceRoom" element={<ReferenceRoom />} />
        <Route path="referenceRoom/:index" element={<IrDetail state="referenceRoom" />} />
        <Route path="financial" element={<Financial />} />
        <Route path="news" element={<News />} />
        <Route path="news/:index" element={<NewsDetail state="news" />} />
        <Route path="recruit" element={<Recruit />} />
        <Route path="recruit/:index" element={<RecruitDetail state="recruit" />} />
        <Route path="stock" element={<Stock />} />
        {/* <Route path="audition" element={<Audition />} /> */}
        <Route path="guideReporting" element={<GuideReporting />} />
        <Route path="legal/:item" element={<Legal />} />
      </Route>
      <Route
        path="*"
        element={<ErrorPage />}
      />
    </Routes>
  );
}

export default App;