import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import BaseButton from "components/ui/button/BaseButton"
import store from '../utils/store';

function NewsLayoutItem(props) {
  const { item } = props;
  const navigate = useNavigate();
  const lang = useSelector(state=>{
    return state.user.language;
  });
  const detailNavigation = (index) => {
    let url = `/${lang}/news`;
    if (index) {
      url = url + `/${index}`;
    }
    navigate(url, { state: "news" });
  };
  return (
    <div onClick={() => detailNavigation(item.id)} className="news-item">
      <div className="news-item__image" style={{ backgroundImage: `url(${item.image})` }}></div>
      <div className="news-item__inner">
        <div className="head-info">
          <div className="head-info__title"><p>{item?.title}</p></div>
          <span className="head-info__date">{item.date}</span>
        </div>
        <p className="foot-info">{item.desc}</p>
      </div>
    </div>
  )
}

function NewsLayout() {
  const [newsList, setNewsList] = useState([]);
  const lang = useSelector(state=>{
    return state.user?.language;
  });

  useEffect(() => {
    const pattern = /!\[\][^\n]*\n+/g;
    const loacle = lang !== 'ko' && lang ? `_${lang}` : '';
    const content_id = '공지사항'+ loacle;
    store.getBoardsByCreateDate(content_id, "DESC", 4)
    .then(data => {
      let list = []
      data.forEach(element => {
        list.push({
          id: element.id,
          image: store.awsFilePath(element.thumbnail),
          title: element.title,
          date: new Date(element.create_date).toLocaleDateString().replace(/\./g, '').replace(/\s/g, '-'),
          desc: JSON.parse(element.body)
            .replace(pattern, '')
            .replace(/\*\*/g, '')
            .replace('<br>', '')
            .replace(/\\/g, ''),
        })
      });
      setNewsList(list)
    })
  }, [lang]);

  return (
    <section className="news-container main-news-wrapper">
      <p className="news-container__title">NEWS</p>
      <div className="news-container__inner">
        <div className="news-list">
          {newsList.map((item, index) => {
            return <NewsLayoutItem key={index} item={item} />
          })}
        </div>
      </div>
    </section>
  )
}
function BannerLayout() {
  const { t } = useTranslation()
  const navigate = useNavigate();
  const lang = useSelector(state=>{
    return state.user.language;
  });

  const handleNavigation = (item) => {
    let url = `/${lang}`;
    if (item) {
      url = url + item;
    }
    navigate(url);
  };
  return (
    <>
      <section className="banner-container main-banner">
        <div className="banner-inner main">
          <div className="banner-desc">
            <p className="banner-desc__title">{t("main.mainBanner.title")}</p>
            <span className="banner-desc__sub">{t("main.mainBanner.subTitle")}</span>
          </div>
        </div>
      </section>
      <section className="banner-container sub-banner games">
        <div className="banner-inner sub-left">
          <div className="banner-desc">
            <p className="banner-desc__title">{t("main.gamesBanner.title")}</p>
            <span className="banner-desc__sub">{t("main.gamesBanner.subTitle")}</span>
            <BaseButton onClick={() => handleNavigation(`/games`)} label={t("main.gamesBanner.buttonText")} color="white" />
          </div>
        </div>
      </section>
      <section className="banner-container sub-banner webtoon">
        <div className="banner-inner sub-right">
          <div className="banner-desc">
            <p className="banner-desc__title">{t("main.webtoonBanner.title")}</p>
            <span className="banner-desc__sub">{t("main.webtoonBanner.subTitle")}</span>
            <BaseButton onClick={() => handleNavigation(`/webtoons`)} label={t("main.webtoonBanner.buttonText")} color="white" />
          </div>
        </div>
      </section>
      <section className="banner-container sub-banner drama">
        <div className="banner-inner sub-left">
          <div className="banner-desc">
            <p className="banner-desc__title">{t("main.dramaBanner.title")}</p>
            <span className="banner-desc__sub">{t("main.dramaBanner.subTitle")}</span>
            <BaseButton onClick={() => handleNavigation(`/dramas`)} label={t("main.dramaBanner.buttonText")} color="white" />
          </div>
        </div>
      </section>
      <section className="banner-container sub-banner artist">
        <div className="banner-inner sub-right">
          <div className="banner-desc">
            <p className="banner-desc__title">{t("main.artistBanner.title")}</p>
            <span className="banner-desc__sub">{t("main.artistBanner.subTitle")}</span>
            <BaseButton onClick={() => handleNavigation(`/artists`)} label={t("main.artistBanner.buttonText")} color="white" />
          </div>
        </div>
      </section>
    </>
  )
}

export default function Main() {
  return (
    <div className="main-container">
      <BannerLayout />
      <NewsLayout />
    </div>
  )
}