import { useState, useEffect } from "react";
import store from "utils/store";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import BannerLayout from "components/ui/banner/BannerLayout"
import Bg from "assets/Content/banner/pages/referenceRoom.png";
import MobileBg from "assets/Content/banner/pages/referenceRoom-m.png";
import Skeleton from '@mui/material/Skeleton';

function TableComponent() {
  const pageUnit = 10
  const [pageIndex, setPageIndex] = useState(0);
  const [pageTokenList, setPageTokenList] = useState([null]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const { t } = useTranslation()
  const navigate = useNavigate();

  const lang = useSelector(state=>{
    return state.user.language;
  });
  const numberOfRows = 10;
  const columns = 3;
  const skeletonRows = new Array(numberOfRows).fill(null);

  useEffect(() => {
    const loacle = lang !== 'ko' && lang ? `_${lang}` : '';
    const content_id = 'IR자료실'+ loacle;
    store.getBoardsByCreateDate(content_id, "DESC", pageUnit, pageTokenList[pageIndex])
    .then(d => {
      const prefix = "https://ghosthomeadmin-storage-c5898b6a153942-main.s3.ap-northeast-2.amazonaws.com/public/"
      const pattern = /!\[\]\(([^)]*)\)+/g;
      let list = [];
      if (!pageTokenList[pageIndex+1] && d.nextToken) {
        setPageTokenList(pageTokenList.concat([d.nextToken]))
      }
      d.items.forEach(element => {
        list.push({
          id: element.id,
          date: new Date(element.create_date).toLocaleDateString().replace(/\./g, '').replace(/\s/g, '-'),
          file: prefix+element.file,
          desc: JSON.parse(element.body).replace(pattern, ''),
          title: element.title,
        })
      });
      setData(list)
      setLoading(false)
    })

  }, [pageIndex, lang, pageTokenList]);

  // 다음 페이지 토큰이 있는경우에만 이동 가능
  const next = () => {
    if (pageTokenList[pageIndex+1]) {
      let index = pageIndex+1
      setPageIndex(index)
    }
  }

  // 이전 페이지 토큰이 있는경우에만 이동 가능
  const prev = () => {
    // null은 조건문통과를 못하므로 조건 추가
    if (pageTokenList[pageIndex-1] || pageTokenList[pageIndex-1] === null) {
      let index = pageIndex-1
      setPageIndex(index)
    }
  }

  const tableHead = [
    'date',
    'dataName',
    'pdf'
  ]
  const detailNavigation = (index) => {
    let url = `/${lang}/referenceRoom`;
    if (index) {
      url = url + `/${index}`;
    }
    navigate(url, { state: "referenceRoom" });
  };
  return (
    <div className="table-wrapper file-detail-wrapper">
      <table className="table-inner">
        <colgroup>
          {tableHead.map((head, index) => {
            return <col key={index} className={head} />;
          })}
        </colgroup>
        <thead className="table-inner__head">
          <tr>
          {tableHead.map((head, index) => {
            if (head !== 'pdf') {
              return <th key={index} className={head}>{t(`referenceRoom.tableHead.${[head]}`)}</th>
            } else {
              return <th key={index} className={head}></th>
            }
          })}
          </tr>
        </thead>
        {loading ?
          <tbody className="table-inner__body skeleton-rows">
          {skeletonRows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {new Array(columns).fill(null).map((column, columnIndex) => (
                <td key={columnIndex} className={`${columnIndex === 1 ? 'dataName' : ''}`}>
                  <div className="desktop">
                    <Skeleton className="default" animation="wave" />
                  </div>
                  <div className="mobile">
                    <Skeleton className="medium" animation="wave" />
                    <Skeleton className="small" animation="wave" />
                  </div>
                </td>
              ))}
            </tr>
          ))}
          </tbody> :
        <tbody className="table-inner__body">
            {data.map((body, index) => {
              return <tr key={index}>
                <td><p>{body.date}</p></td>
                <td className="dataName">
                  <div className="dataName__inner">
                    <p onClick={() => detailNavigation(body.id)}>{body.title}</p>
                    <span className={body.file && 'pdf'}></span>
                  </div>
                  <span className="date">{body.date}</span>
                </td>
                <td onClick={() => {window.open(body.file)}} className={body.file && 'pdf'}><p>{body.file && t("referenceRoom.tableBody.pdfDownload")}</p></td>
              </tr>
            })}
        </tbody>}
      </table>
      <div className="pagination">
        <div className="pagination__inner">
          <button className={`prev ${pageIndex === 0 ? 'disabled' : ''}`} onClick={() => prev()}>{t("component.page.prev")}</button>
          <div className="row"></div>
          <button className={`next ${pageTokenList.length === pageIndex+1 ? 'disabled' : ''}`} onClick={() => next()}>{t("component.page.next")}</button>
        </div>
      </div>

      {!data.length && <div className="no-data"><p>{t("component.message.Not found")}</p></div>}
    </div>
  )
}

export default function ReferenceRoom() {
  const bannerInfo = {
    title: 'referenceRoom.mainBannerTitle',
    background: Bg,
    backgroundMobile: MobileBg,
    textColor: 'black',
    size: 'small'
  }
  return (
    <div className="disclosure-container">
      <BannerLayout {...bannerInfo} />
      <div className="disclosure-inner">
        <section className="section-layout">
          <TableComponent />
        </section>
      </div>
    </div>
  )
}