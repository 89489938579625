import React from 'react';

class ExternalHTMLRenderer extends React.Component {
  constructor(props) {
    super(props);
    this.externalHTML = `
    <div class="wrap">
    <div class="disTit tal">
      <h1>Terms of Service</h1>
    </div>
    <div class="artContent mt40">

<p style="margin-top: 8px;margin-bottom: 8px;text-align: center;white-space: normal;vertical-align: middle"><strong><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">Terms of Service</span></strong></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">Lastly Updated Oct 16, 2023</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">PLEASE READ THE FOLLOWING TERMS OF SERVICE CAREFULLY BEFORE USING GHOST STUDIO SERVICES. THEY INCLUDE A PROVISION WAIVING THE RIGHT TO PURSUE ANY CLASS, GROUP OR REPRESENTATIVE CLAIM AND REQUIRING YOU TO PURSUE CERTAIN DISPUTES THROUGH INDIVIDUAL ARBITRATION UNLESS YOU OPT OUT WITHIN THE SPECIFIED TIME FRAME. SEE “DISPUTE RESOLUTION” BELOW FOR MORE INFORMATION.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">These Terms of Service are a legal agreement between you and us. By using any games, applications, content and services (the “<strong>Service</strong>”) for any platform or device, you agree to Terms of Service and Privacy Policy of GHOST STUDIO Limited (“<strong>We</strong>”, “<strong>Us</strong>”). If you do not agree to these Terms, do not use the Service.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">If you violate the Terms, we reserve the right to issue you a warning regarding the violation or immediately terminate or suspend any or all accounts you have created using the Service. You agree that we don't need to provide you notice before terminating or suspending your account(s), but we may provide such notice in our sole discretion.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;"><br></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;"><strong><span style="font-family: arial, helvetica, sans-serif; color: rgb(51, 51, 51);">User Data</span></strong></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">You agree to immediately provide GHOST STUDIO with any future changes to data entered upon registration, in particular any change to the email address. Users are obliged to confirm for GHOST STUDIO the accuracy of their data upon request.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">You are solely responsible for the activity that occurs on or in connection with your account. You are required to maintain strict confidentiality regarding all login data, identification, and passwords.&nbsp;&nbsp;The terms "login data", "identification", and "passwords" include all letter and/or character and/or number sequences used to authenticate the user and prevent unauthorized use by third parties. The password should not be identical to the player name, and it should consist of a combination of numbers and letters.&nbsp;&nbsp;You are required to protect all login data, identification, and passwords from third-party access.&nbsp;</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">In the event that you have reason to believe that third parties have obtained or may have obtained unauthorized login data, identification, or passwords, you shall inform GHOST STUDIO immediately of such unauthorized use and change your data or have it changed by GHOST STUDIO. In this case or in the event that GHOST STUDIO has concrete evidence of misuse of data, GHOST STUDIO has the right to temporarily block access to the user's account. If there is evidence of misuse of data, GHOST STUDIO shall inform the user.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">You under no circumstances entitled to use the login data of another user, unless the rules of the respective online or mobile game or other service allow for exceptions.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;"><br></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;"><strong><span style="font-family: arial, helvetica, sans-serif; color: rgb(51, 51, 51);">Grant of a Limited License to Use the Service</span></strong></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">Subject to your agreement to and continuing compliance with these Terms of Service and any other relevant GHOST STUDIO policies, GHOST STUDIO grants you a non-exclusive, non-transferable, non-sublicensable, revocable and limited license to access and use the Service for your own non-commercial entertainment purposes. You agree not to use the Service for any other purpose.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">The following restrictions apply to the use of the Service:</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">·<span style="font-family: arial, helvetica, sans-serif; color: rgb(51, 51, 51); font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>You shall not create an Account or access the Service if you are under the age of 16; You shall restrict use by minors, and you will deny access to children under the age of 16. You accept full responsibility for any unauthorized use of the Service by minors. You are responsible for any use of your credit card or other payment instrument (e.g. paypal) by minors.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;vertical-align: middle"><span style="font-family: arial, helvetica, sans-serif; color: rgb(51, 51, 51); font-size: 14px;">·<span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>You shall not (or attempt to) purchase, sell, rent, transfer or give away your Account, create an Account using a false identity or information, or on behalf of someone other than yourself; You shall not use the Service if you have previously been removed by GHOST STUDIO, or previously been banned from playing any GHOST STUDIO game.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;vertical-align: middle"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px; color: rgb(51, 51, 51);">·<span style="font-size: 14px; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>You shall use your Account only for non-commercial purposes; You shall not use the Service to advertise, or solicit, or transmit any commercial advertisements, including chain letters, junk or spam e-mail or repetitive or misleading messages to anyone.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;vertical-align: middle"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px; color: rgb(51, 51, 51);">·<span style="font-size: 14px; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>You shall not redeem virtual chips or virtual coins for 'real world' money, goods or other items of monetary value from any platforms or any other person.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">You understand and agree that your use of this Service is predicated upon your waiver of any right to sue GHOST STUDIO or its affiliates directly or to participate in a suit for any losses or damages resulting from using of this Service in a cause of action of losing money or anything of value at or on an illegal gambling game.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">You are obliged to abstain from any measure which may compromise or interrupt the proper functioning of GHOST STUDIO' websites, any individual services and/or offers there, or its online and mobile games. Users are also required to abstain from any measure which may allow unauthorized access to data. Content may only be called up in a manner that does not affect other users' use of GHOST STUDIO' websites and content. The transfer of data or software that may affect the hardware or software of recipients is not permitted.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;"><br></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;background-color: white"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;"><strong><span style="font-family: arial, helvetica, sans-serif; color: rgb(51, 51, 51);">Game Currencies and Goods</span></strong></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;background-color: white"><span style="color: rgb(74, 74, 74); font-family: arial, helvetica, sans-serif; font-size: 14px;">There is never any requirement to make any purchase of any kind to use the Service. &nbsp;The Service may include an opportunity to earn or purchase virtual, in-game currency, including but not limited to virtual coins, points, credits, bonuses and/or chips all for use in the Service ("<strong>Coins</strong>") or virtual in-game items or collections (together with the Coins, “<strong>Virtual Items</strong>”).&nbsp; A certain number of Coins will be made available to you to collect when you log into the Service at recurring time intervals.&nbsp; If you exhaust your supply of Coins, you may elect to purchase additional Coins and continue to play the games through the Service or you may wait until additional free Coins are available to you.&nbsp; Coins and other Virtual Items are licensed to you by us for your use through the Service, subject to the limitations and other terms set out in greater detail below.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;background-color: white"><span style="color: rgb(74, 74, 74); font-family: arial, helvetica, sans-serif; font-size: 14px;">If you wish to purchase Coins or other Virtual Items, you will be required to pay a fee using “real world” money to obtain the Virtual Items. Virtual Items can never be redeemed or cashed out for “real world” money, goods, or any other item of monetary value from GHOST STUDIO or any other party. You understand that you have no right or title in the Virtual Items appearing or originating in any GHOST STUDIO game, whether “awarded” in a game or “purchased” from GHOST STUDIO, or any other attributes associated with an Account or stored on the Service.&nbsp;</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;background-color: white"><span style="color: rgb(74, 74, 74); font-family: arial, helvetica, sans-serif; font-size: 14px;">You may not transfer, purchase, sell, or exchange Virtual Items outside the Service, or attempt to sell, give or trade in the "real world" anything that appears or originates in the Service unless otherwise expressly authorized by GHOST STUDIO in writing. We won't recognize those transfers as legitimate. Accordingly, you may not sublicense, trade, sell or attempt to sell in-game Virtual Items for "real" money, or exchange Virtual Items for value of any kind outside of a game, without GHOST STUDIO's written permission. Doing so is a violation of these Terms and may result in termination of your Account and/or legal action taken against you, any such transfer or attempted transfer is prohibited and void.&nbsp;</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;background-color: white"><span style="color: rgb(74, 74, 74); font-family: arial, helvetica, sans-serif; font-size: 14px;">Other than a limited, personal, revocable, non-transferable, non-sublicense able license to use the Virtual Items with the Services, you have no right or title in or to any such Virtual Items appearing or originating with the Services, or any other attributes associated with use of the Services or stored within the Services. GHOST STUDIO retains the right to manage, regulate, control, modify and/or eliminate Virtual Items at its sole discretion, and GHOST STUDIO shall have no liability to you or anyone for the exercise of such rights. Prices and availability of Virtual Items are subject to change without notice. In addition to the foregoing, GHOST STUDIO may selectively remove or revoke Virtual Items associated with your Account upon its sole discretion.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;background-color: white"><span style="color: rgb(74, 74, 74); font-family: arial, helvetica, sans-serif; font-size: 14px;">Virtual Items may only be held by legal residents of countries where access to and use of the Services are permitted. Virtual Items may only be purchased or acquired from us and through means we provide on the applicable website or otherwise expressly authorize. GHOST STUDIO does not recognize any purchases or transfers made outside of the Service on any other platform or e-commerce website, and shall not be liable for any claims or damages caused to the users with respect to Virtual Items purchased or obtained from third parties, and not through the means provided within the game. We reserve the right to refuse your request to purchase and/or acquire Virtual Items for any reason or revise the pricing for the Virtual Items at any time. You can get a limited license to Virtual Items by visiting the purchase page in one of our games, providing your billing information, confirming the particulars of your purchase and re-affirming your agreement to these Terms.&nbsp;</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;background-color: white"><span style="color: rgb(74, 74, 74); font-family: arial, helvetica, sans-serif; font-size: 14px;">Virtual Items purchased in our games on other applications stores or platforms such as but not limited to Facebook, Apple iOS, or Android will be subject to those platforms' payment terms and conditions. GHOST STUDIO does not control how you can pay on those platforms and shall not be liable for processing the payment by such third parties. Please review those platforms' terms of service for additional information.&nbsp;</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;background-color: white"><span style="color: rgb(74, 74, 74); font-family: arial, helvetica, sans-serif; font-size: 14px;">Your order for Virtual Items will represent an offer to us to obtain a limited license for the relevant service(s) or virtual in-game item(s) which will be accepted by us when we make the Virtual Items available in your account for you to use in our games or debit your credit card or other account through which you paid, whichever comes first. Your limited license to Virtual Items for use in GHOST STUDIO games is a service provided by GHOST STUDIO that starts when we accept your payment or redemption of third party virtual currency. When you get a limited license to use Virtual Items, they will reside in your Account until discharged through use of the Service or otherwise surrendered as a result of termination of the Services in accordance with these Terms.&nbsp;</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;background-color: white"><span style="color: rgb(74, 74, 74); font-family: arial, helvetica, sans-serif; font-size: 14px;">When purchasing Virtual Items or other content as may be made available, you agree to pay us the applicable charges for your purchase, including applicable taxes incurred by you or anyone using an Account registered to you, using a valid charge card or other payment method we may accept in accordance with the billing terms and prices in effect at the time the fee or charge becomes payable.&nbsp;</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;background-color: white"><span style="color: rgb(74, 74, 74); font-family: arial, helvetica, sans-serif; font-size: 14px;">All sales of Virtual Items and/or other content are final. If your Account is charged for items you did not purchase, or you did not receive the items you purchased, or you were charged an incorrect amount, you may request a refund or correction in accordance to payment provider policy. Any refund request must be received within 96 hours from the time of purchase in order for refunds to be issued in accordance to payment provider policy. Refund requests past 96 hours from time of purchase will be honored according only in accordance to our payment policy.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;background-color: white"><span style="color: rgb(74, 74, 74); font-family: arial, helvetica, sans-serif; font-size: 14px;">Other than charges to your Account, you agree to notify us about any billing problems or discrepancies within 30 days after they first appear on your Account statement. If you do not bring them to our attention within 30 days, you agree that you waive your right to dispute such problems or discrepancies.&nbsp;</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;background-color: white"><span style="color: rgb(74, 74, 74); font-family: arial, helvetica, sans-serif; font-size: 14px;">You are responsible for and agree to reimburse us for all reversals, charge-backs, claims, fees, fines, penalties and other liability incurred by us (including costs and related expenses) that were caused by or arising out of payments that you authorized or accepted. You understand that we may suspend or terminate your Account if for any reason a charge you authorize us to make to your credit card or other method of payment cannot be processed or is returned to us unpaid and, if such event occurs, you shall immediately remit to us payment for such charge through an alternative payment method.&nbsp;</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;background-color: white"><span style="color: rgb(74, 74, 74); font-family: arial, helvetica, sans-serif; font-size: 14px;">You acknowledge that GHOST STUDIO is not required to provide a refund for any other reason, and that you will not receive money or other compensation for unused virtual items when an Account is closed, whether such closure was voluntary or involuntary, or whether you made a payment through GHOST STUDIO.net, applications stores or another platform such as but not limited to Apple, Google, Facebook, or any other sites or platforms where we offer our Services. All Virtual Items are forfeited by You if your Account is terminated or suspended for any reason, in GHOST STUDIO's sole and absolute discretion, or if the Service is no longer available. If your Account, or a particular subscription for the Service associated with your Account, is terminated, suspended and/or if any Virtual Items are selectively removed or revoked from your Account, no refund will be granted, no Virtual Items will be credited to you or converted to cash or other forms of reimbursement.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;background-color: white"><span style="color: rgb(74, 74, 74); font-family: arial, helvetica, sans-serif; font-size: 14px;"><br></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;"><strong><span style="font-family: arial, helvetica, sans-serif; color: rgb(51, 51, 51);">Our Intellectual Property&nbsp;</span></strong></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">GHOST STUDIO' websites and mobile games include various content that is protected by trademarks, copyrights, or other means for the benefit of GHOST STUDIO or third parties. Unless explicitly permitted within these general terms and conditions, you may not edit, copy, distribute, publicly reproduce, use for advertising purposes, or use beyond the contractually agreed purposes any of GHOST STUDIO' websites, mobile games, or the content or any portion thereof. Only technical copies intended for browsing purposes and permanent copies intended solely for private use shall be permitted. Copyright information and brand names may not be changed, hidden, or removed.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">The term "content" includes all data, images, text, graphics, music, sounds, sound sequences, videos, software programs and codes, and other information provided by GHOST STUDIO. The term "content" also includes, in particular, all services available for download.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;"><br></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;"><strong><span style="font-family: arial, helvetica, sans-serif; color: rgb(51, 51, 51);">Third Party Material</span></strong></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">The Service contains links to websites, services and content owned and/or operated by third parties, for instance third parties who may invite you to participate in promotional offers or rewards programs or third-party advertisers, affiliate advertising networks or payment providers. Any separate charges or obligations that you may incur in your dealings with these third parties are your sole responsibility. We are not responsible for any such third-party websites, services or content and do not have control over any materials made available therein. Our inclusion of a link to a third-party website, services or content in the Service does not in any way imply our endorsement, advertising, or promotion of such websites, services or content or any materials made available therein. By accessing a third-party website, services or content you accept that we do not exercise any control over such websites, services or content and have no responsibility for them. The third-party sites may collect data or solicit personal information from you. We are not responsible for privacy policies, or for the collection, use or disclosure of any information those sites may collect. We encourage you to familiarize yourself with the terms of service and privacy policy applicable to any third-party website, services or content you may access.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">We do not warrant or endorse and do not assume and will not have any liability or responsibility to you or any other person for any third-party products, services, materials or websites. Please note that the applicable third party is fully responsible for all goods and services it provides to you and for any and all damages, claims, liabilities and costs it may cause you to suffer, directly or indirectly, in full or in part.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;"><strong><span style="font-size: 12px;font-family: Tahoma, sans-serif;color: rgb(51, 51, 51)"><br></span></strong></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;"><strong><span style="font-family: arial, helvetica, sans-serif; color: rgb(51, 51, 51);">Responsibility For User Content</span></strong></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">You are solely responsible for any text, communications, images, and other data, information and content that you submit in the Service, or transmit to other users of the Service (collectively, "User Content"). You are responsible for complying with all laws applicable to your User Content. You agree not to submit to the Service, or transmit to other users of the Service, any defamatory, inaccurate, abusive, obscene, profane, offensive, sexually oriented, threatening, harassing, racially offensive, or illegal material, or any material that infringes or violates another party's rights (including, but not limited to, intellectual property rights, or rights of privacy or publicity). You will not provide inaccurate, misleading or false information to us or to any other user of the Service. If information provided to us, or another user of the Service, subsequently becomes inaccurate, misleading or false, you will promptly notify us of such change.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">We may, in our sole discretion and without notice, review and delete any User Content, but are under no obligation to do so. We have no responsibility for the conduct of any user in the Service, including any User Content submitted in the Service. We assume no responsibility for monitoring the Service for inappropriate content or conduct. Your use of the Service is at your own risk.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">You hereby grant us an exclusive, irrevocable, sub-licensable, transferable, worldwide, royalty-free license to reproduce, modify, create derivative works from, publish, distribute, sell, transfer, transmit, publicly display and use any User Content and to incorporate the same in other works in any form, media, or technology now known or later developed.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">You further acknowledge that we can use and exploit your name, likeness and any other information or material included in any User Content and in connection with any User Content or your User Account in accordance with our Privacy Policy at<span style="font-family: arial, helvetica, sans-serif; font-size: 14px; color: rgb(0, 0, 0);">&nbsp;</span></span><a href="PrivacyPolicy" style="text-decoration: underline; font-family: arial, helvetica, sans-serif; font-size: 14px; color: rgb(0, 0, 0);"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px; color: rgb(0, 0, 0);">http://www.ghoststudio.net/legal/privacypolicy</span></a><span style="font-family: arial, helvetica, sans-serif; font-size: 14px; color: rgb(0, 0, 0);">.</span><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;"> Except as prohibited by law, you waive any rights of attribution and/or any moral rights you may have in your User Content, regardless of whether your User Content is altered or changed in any manner.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">You acknowledge and agree that all User Content (other than your personal data) whether publicly posted or privately transmitted to the Service is at your sole responsibility and risk. We disclaim any responsibility for the backup and/or retention of any User Content transmitted to the Service.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;"><br></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;"><strong><span style="font-family: arial, helvetica, sans-serif; color: rgb(51, 51, 51);">Price and Payment</span></strong></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">You may purchase unreal currency from Platform(including Facebook, Apple App Store, Google play and Amazon Appstore) that we decide to work with in exchange for real money. This is a transaction between you and Platform and us is not a party. If you purchase Platform Credits, you are agreeing to Platform's terms relating to payment and Platform Credits. We are not responsible or liable for any issues related to the services provided by Platform to you in connection with the purchase of Platform Credits. Once you have completed your purchase of Platform Credits you may redeem Platform Credits for in-game currencies and goods offered in our games hosted by Platform. If you are under the age of 21, you may use Payments only with the involvement of your parent or guardian. Make sure you review these Terms with your parent or guardian so that you both understand all of your rights and responsibilities.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">In some cases, you can use Facebook Credits, a stored value (gift card), credit or debit card, PayPal, or other similar accounts (each referred to herein as “Payment Information“), to pay for optional Services and/or upgrades. You are responsible for all charges incurred, including applicable taxes, and all purchases made by you or anyone that uses your account, including your family members or friends. GHOST STUDIO may revise the pricing for the goods and services offered through the Service at any time.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">YOU ACKNOWLEDGE THAT GHOST STUDIO IS NOT REQUIRED TO PROVIDE A REFUND FOR ANY REASON, AND THAT YOU WILL NOT RECEIVE MONEY OR OTHER COMPENSATION FOR UNUSED VIRTUAL ITEMS WHEN AN ACCOUNT IS CLOSED, WHETHER SUCH CLOSURE WAS VOLUNTARY OR INVOLUNTARY.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;"><br></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;"><strong><span style="font-family: arial, helvetica, sans-serif; color: rgb(51, 51, 51);">Term and Termination</span></strong></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">Company may terminate the Agreement by deleting your account with the Service, in the event that it is required to do so by law or court order, in the event that it becomes aware or has reason to believe that you have violated any of the terms of this Agreement, or if there is any other reason which justifies refusing the acceptance of you as a user.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">You may also terminate this Agreement by requesting us to delete your account with the Service, and such request shall be handled promptly after it is accepted.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">Any provisions relating to indemnification, limitation of liability and disclaimers of any kind shall remain in full force and effect after termination of the Agreement.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;"><br></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;"><strong><span style="font-family: arial, helvetica, sans-serif; color: rgb(51, 51, 51);">Disclaimer of Warranty</span></strong></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">You agree that your use of the Service shall be at your sole risk. To the fullest extent permitted by law, we, our officers, directors, employees, and agents disclaim all warranties, explicit or implied, in connection with the Service and your use thereof including implied warranties of merchantability, title, fitness for a particular purpose or non-infringement, usefulness, authority, accuracy, completeness, and timeliness. We make no warranties or representations about the accuracy or completeness of the content of the Service of the content of any sites linked to the Service and assume no liability or responsibility for any:</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;vertical-align: middle"><span style="font-family: arial, helvetica, sans-serif; color: rgb(51, 51, 51); font-size: 14px;">·<span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Errors, mistakes, or inaccuracies of content;</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;vertical-align: middle"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px; color: rgb(51, 51, 51);">·<span style="font-size: 14px; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Personal injury or property damage, of any nature whatsoever, resulting from your access to and use of the Service;</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;vertical-align: middle"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px; color: rgb(51, 51, 51);">·<span style="font-size: 14px; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Any interruption or cessation of transmission to or from the Service;</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;vertical-align: middle"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px; color: rgb(51, 51, 51);">·<span style="font-size: 14px; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Any bugs, viruses, Trojan horses, or the like which may be transmitted to or through the Service by any third party;</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;vertical-align: middle"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px; color: rgb(51, 51, 51);">·<span style="font-size: 14px; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Any errors or omissions in any content (other than your personal data) or for any loss or damage of any kind incurred as a result of the use of any content (other than your personal data) posted, emailed, transmitted, or otherwise made available via the Service.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;vertical-align: middle"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px; color: rgb(51, 51, 51);"><br></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;"><strong><span style="font-family: arial, helvetica, sans-serif; color: rgb(51, 51, 51);">Limitation of Liability</span></strong></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">TO THE EXTENT ALLOWED UNDER APPLICABLE LAWS, GHOST STUDIO AND THE GHOST STUDIO AFFILIATES ARE NOT LIABLE</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">1. FOR ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, INCLUDING FOR LOSS OF PROFITS, GOODWILL OR DATA, IN ANY WAY WHATSOEVER ARISING OUT OF THE USE OF, OR INABILITY TO USE, THE SERVICE;</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">2. FOR THE CONDUCT OF THIRD PARTIES, INCLUDING OTHER USERS OF THE SERVICE AND OPERATORS OF EXTERNAL SITES.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">THE RISK OF USING THE SERVICE AND EXTERNAL SITES RESTS ENTIRELY WITH YOU AS DOES THE RISK OF INJURY FROM THE SERVICE AND EXTERNAL SITES.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">TO THE FULLEST EXTENT ALLOWED BY ANY LAW THAT APPLIES, THE DISCLAIMERS OF LIABILITY IN THESE TERMS APPLY TO ALL DAMAGES OR INJURY CAUSED BY THE SERVICE, OR RELATED TO USE OF, OR INABILITY TO USE, THE SERVICE, UNDER ANY CAUSE OF ACTION IN ANY JURISDICTION, INCLUDING, WITHOUT LIMITATION, ACTIONS FOR BREACH OF WARRANTY, BREACH OF CONTRACT OR TORT (INCLUDING NEGLIGENCE).</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">TO THE MAXIMUM EXTENT PERMISSIBLE UNDER APPLICABLE LAWS, THE TOTAL LIABILITY OF GHOST STUDIO AND/OR THE GHOST STUDIO AFFILIATES IS LIMITED TO THE TOTAL AMOUNT YOU HAVE PAID GHOST STUDIO AND/OR THE GHOST STUDIO AFFILIATE IN THE ONE HUNDRED AND EIGHTY DAYS (180) DAYS IMMEDIATELY PRECEDING THE DATE ON WHICH YOU FIRST ASSERT ANY SUCH CLAIM.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">IF YOU HAVE NOT PAID GHOST STUDIO OR ANY GHOST STUDIO AFFILIATE ANY AMOUNT IN THE ONE HUNDRED AND EIGHTY DAYS (180) DAYS IMMEDIATELY PRECEDING THE DATE ON WHICH YOU FIRST ASSERT ANY SUCH CLAIM, YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY DISPUTE WITH GHOST STUDIO AND/OR ANY GHOST STUDIO AFFILIATE IS TO STOP USING THE SERVICE AND TO CANCEL YOUR ACCOUNT.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">Some states or countries do not allow the exclusion of certain warranties or the limitations/exclusions of liability described above. So these limitations/exclusions may not apply to you if you reside in one of those states or countries.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;"><br></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="font-size: 14px; font-family: arial, helvetica, sans-serif;"><strong><span style="font-size: 14px; color: rgb(51, 51, 51);">Indemnification</span></strong></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">You agree to defend, indemnify, and hold us harmless from and against any claim, liability, loss, injury, damage, cost, or expense (including reasonable attorneys’ fees) incurred by us arising out of or from your access and use of the Services, your violation of these Terms or any Content posted, transmitted or provided by you or on your behalf.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;"><br></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;"><strong><span style="font-family: arial, helvetica, sans-serif; color: rgb(51, 51, 51);">Force Majeure</span></strong></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">GHOST STUDIO shall not be liable for any delay or failure to perform resulting from causes outside the reasonable control of GHOST STUDIO, including without limitation any failure to perform hereunder due to unforeseen circumstances or cause beyond GHOST STUDIO's control such as acts of God, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, strikes, epidemics,&nbsp;&nbsp;or shortages of transportation facilities, fuel, energy, labor or materials.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;"><br></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;"><strong><span style="font-family: arial, helvetica, sans-serif; color: rgb(51, 51, 51);">Privacy</span></strong></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;"></span><span style="font-size: 14px; font-family: arial, helvetica, sans-serif; color: rgb(51, 51, 51);">GHOST STUDIO's Privacy Policy<span style="font-size: 14px; font-family: Tahoma, sans-serif; color: rgb(51, 51, 51);">&nbsp;a</span></span><span style="font-size: 14px; font-family: arial, helvetica, sans-serif; color: rgb(51, 51, 51); text-decoration: none;">t&nbsp;</span><span style="font-size: 14px; color: rgb(51, 51, 51); text-decoration: underline; font-family: DengXian;"><a href="PrivacyPolicy" style="color: rgb(149, 79, 114)"><span style="font-size: 14px; text-decoration: underline; font-family: Tahoma, sans-serif; color: black;">http://www.ghoststudio.net/legal/privacypolicy</span></a></span><span style="font-family: arial, helvetica, sans-serif; color: rgb(0, 0, 0); font-size: 14px; text-decoration: none;">&nbsp;</span><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">tells you how we collect and use information about you and your computer or mobile device, and how you can use the Service to share such information with others. You understand that through your use of our Service you acknowledge the collection, use and sharing of this information as described in GHOST STUDIO's Privacy Policy. If you do not agree with our Privacy Policy, then we may stop providing you with our Services.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">We encourage you to read the GHOST STUDIO Privacy Policy carefully and use it to make informed decisions.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;"><br></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;"><strong><span style="font-family: arial, helvetica, sans-serif; color: rgb(51, 51, 51);">Disputes Resolution</span></strong></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;font-size: medium;white-space: normal;vertical-align: baseline"><span style="color: rgb(33, 37, 41); border: 1px none windowtext; padding: 0px; font-family: arial, helvetica, sans-serif; font-size: 14px;">PLEASE READ THIS "DISPUTE RESOLUTION" PROVISION CAREFULLY, BECAUSE IT REQUIRES YOU TO ARBITRATE DISPUTES WITH GHOST STUDIO AND IT LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;font-size: medium;white-space: normal;vertical-align: baseline"><span style="color: rgb(33, 37, 41); font-family: arial, helvetica, sans-serif; font-size: 14px;">THIS PROVISION PRECLUDES YOU FROM BRINGING ANY CLASS, COLLECTIVE, OR REPRESENTATIVE ACTION AGAINST GHOST STUDIO. IT ALSO PRECLUDES YOU FROM PARTICIPATING IN OR RECOVERING RELIEF UNDER ANY CURRENT OR FUTURE CLASS, COLLECTIVE, OR REPRESENTATIVE ACTION AGAINST GHOST STUDIO BY SOMEONE ELSE. IN ADDITION, ARBITRATION PRECLUDES YOU FROM SUING IN COURT OR FROM HAVING A JURY TRIAL.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;font-size: medium;white-space: normal;vertical-align: baseline"><span style="color: rgb(33, 37, 41); font-family: arial, helvetica, sans-serif; font-size: 14px;">WHETHER TO AGREE TO ARBITRATION IS AN IMPORTANT DECISION. IT IS YOUR DECISION TO MAKE AND YOU SHOULD NOT RELY SOLELY ON THE INFORMATION PROVIDED IN THIS AGREEMENT, AS IT IS NOT INTENDED TO CONTAIN A COMPLETE EXPLANATION OF THE CONSEQUENCES OF ARBITRATION. YOU SHOULD TAKE REASONABLE STEPS TO CONDUCT FURTHER RESEARCH AND TO CONSULT WITH OTHERS REGARDING THE CONSEQUENCES OF YOUR DECISION. YOU MAY OPT OUT OF THIS ARBITRATION PROVISION BY FOLLOWING THE INSTRUCTIONS BELOW. IF YOU ACCEPT THE TERMS OF SERVICE AND DO NOT OPT OUT OF THE DISPUTE RESOLUTION PROVISION, YOU CANNOT PARTICIPATE IN A LAWSUITS, EVEN IF A CLASS IS CERTIFIED.</span></p>
<p style="margin-top: 8px;margin-bottom: 8px;margin-left: 24px;vertical-align: baseline"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;"><strong><span style="font-family: arial, helvetica, sans-serif; color: rgb(33, 37, 41);">A.<span style="font-family: arial, helvetica, sans-serif; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span></strong><strong><span style="font-family: arial, helvetica, sans-serif; color: rgb(33, 37, 41); border: 1px none windowtext; padding: 0px;">Scope of Arbitration Provision.&nbsp;</span></strong><span style="font-family: arial, helvetica, sans-serif; color: rgb(33, 37, 41);">You and GHOST STUDIO agree that any dispute, claim or controversy arising out of or relating to your access to or use of any GHOST STUDIO Offering or to these Terms of Service, (including without limitation any dispute concerning the breach, enforcement, construction, validity, interpretation, enforceability, or arbitrability of these Terms of Service) (a&nbsp;<strong><span style="font-family: arial, helvetica, sans-serif; border: 1px none windowtext; padding: 0px;">"Dispute"</span></strong>), no matter when such Dispute arose or arises, shall be determined by arbitration, except that you and GHOST STUDIO are NOT required to arbitrate any Dispute in which either party seeks equitable and other relief for the alleged unlawful use of copyrights, trademarks, trade names, logos, trade secrets, or patents.</span></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;margin-left: 24px;vertical-align: baseline"><span style="font-size: 14px; font-family: arial, helvetica, sans-serif;"><strong><span style="font-size: 14px; color: rgb(33, 37, 41);">B.<span style="font-size: 14px; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;</span></span></strong><strong><span style="font-size: 14px; color: rgb(33, 37, 41);">Governing Law.&nbsp;</span></strong><span style="font-size: 14px; color: rgb(33, 37, 41);">This Terms of Service will be governed by and construed in accordance with the laws of Hong Kong without reference to its conflict of law principles.</span></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;margin-left: 24px;vertical-align: baseline"><span style="font-size: 14px; font-family: arial, helvetica, sans-serif;"><strong><span style="font-size: 14px; color: rgb(33, 37, 41);">C.<span style="font-size: 14px; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span></strong><strong><span style="font-size: 14px; color: rgb(33, 37, 41); border: 1px none windowtext; padding: 0px;">Location of Arbitration and Applicable Rules.</span></strong><span style="font-size: 14px; color: rgb(33, 37, 41);">&nbsp;You and GHOST STUDIO agree that the seat of such arbitration shall be Hong Kong. You and GHOST STUDIO agree that such arbitration shall be conducted by a single arbitrator in accordance with the then effective HKIAC Rules, as modified by these Terms of Service.</span></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;margin-left: 24px;vertical-align: baseline"><span style="font-size: 14px; font-family: arial, helvetica, sans-serif;"><strong><span style="font-size: 14px; color: rgb(33, 37, 41);">D.<span style="font-size: 14px; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;</span></span></strong><strong><span style="font-size: 14px; color: rgb(33, 37, 41); border: 1px none windowtext; padding: 0px;">Authority of Arbitrator.</span></strong><span style="font-size: 14px; color: rgb(33, 37, 41);">&nbsp;With the exception of class procedures and remedies as discussed below under "Waiver of Class Relief," the arbitrator shall have the authority to grant any remedy that would otherwise be available in court.</span></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;margin-left: 24px;vertical-align: baseline"><span style="font-size: 14px; font-family: arial, helvetica, sans-serif;"><strong><span style="font-size: 14px; color: rgb(33, 37, 41);">E.<span style="font-size: 14px; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span></strong><strong><span style="font-size: 14px; color: rgb(33, 37, 41); border: 1px none windowtext; padding: 0px;">Confidentiality.</span></strong><span style="font-size: 14px; color: rgb(33, 37, 41);">&nbsp;You and GHOST STUDIO shall maintain the confidential nature of the arbitration proceedings and the arbitration award, including the arbitration hearing, except as may be necessary to prepare for or conduct the arbitration hearing on the merits, or except as may be necessary in connection with a court application for a preliminary remedy, a judicial challenge to an award or its enforcement, or unless otherwise required by law or judicial decision.</span></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;margin-left: 24px;vertical-align: baseline"><span style="font-size: 14px; font-family: arial, helvetica, sans-serif;"><strong><span style="font-size: 14px;">F.<span style="font-size: 14px; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span></strong><strong><span style="font-size: 14px; color: rgb(33, 37, 41); border: 1px none windowtext; padding: 0px;">Arbitration</span></strong><strong><span style="font-size: 14px; color: rgb(74, 74, 74);">Shall Proceed Individually</span></strong><span style="font-size: 14px; color: rgb(74, 74, 74);">.&nbsp;</span><span style="font-size: 14px;">You and GHOST STUDIO agree that the arbitration of any Dispute shall proceed on an individual basis, and neither you nor GHOST STUDIO may bring a claim as a part of a class, group, collective, coordinated, consolidated or mass arbitration (each, a “<strong>Collective Arbitration</strong>”). Without limiting the generality of the foregoing (and as an illustrative but not exhaustive example), a claim to resolve any Dispute against GHOST STUDIO will be deemed a Collective Arbitration if (i) two (2) or more similar claims for arbitration are filed concurrently by or on behalf of one or more claimants; and (ii) counsel for the claimants are the same, share fees or coordinate across the arbitrations. “Concurrently” for purposes of this provision means that both arbitrations are pending (filed but not yet resolved) at the same time.</span></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;margin-left: 24px;vertical-align: baseline"><span style="font-size: 14px; font-family: arial, helvetica, sans-serif;"><strong><span style="font-size: 14px; color: rgb(33, 37, 41);">G.<span style="font-size: 14px; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;</span></span></strong><strong><span style="font-size: 14px; color: rgb(33, 37, 41); border: 1px none windowtext; padding: 0px;">WAIVER OF CLASS RELIEF.&nbsp;</span></strong><span style="font-size: 14px; color: rgb(33, 37, 41);">WHETHER THE DISPUTE IS HEARD IN ARBITRATION OR IN COURT, YOU AGREE THAT YOU AND GHOST STUDIO WILL NOT COMMENCE AGAINST THE OTHER A CLASS ACTION, CLASS ARBITRATION OR OTHER REPRESENTATIVE ACTION OR PROCEEDING. YOU AND GHOST STUDIO ARE EACH WAIVING RESPECTIVE RIGHTS TO A TRIAL BY JURY OR TO PARTICIPATE IN ANY PAST, PENDING, OR FUTURE CLASS ACTION OR ANY OTHER CONSOLIDATED OR REPRESENTATIVE PROCEEDING.</span></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;margin-left: 24px;vertical-align: baseline"><span style="font-size: 14px; font-family: arial, helvetica, sans-serif;"><strong><span style="font-size: 14px; color: rgb(33, 37, 41);">H.<span style="font-size: 14px; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;</span></span></strong><strong><span style="font-size: 14px; color: rgb(33, 37, 41); border: 1px none windowtext; padding: 0px;">Allocation of Arbitration Fees.</span></strong><span style="font-size: 14px; color: rgb(33, 37, 41);">&nbsp;If you assert a Dispute as a consumer, you and GHOST STUDIO will pay arbitration fees severally and equally charged by HKIAC in connection with any arbitration under this section, including any remaining Case Management Fee and all professional fees for the arbitrator's services. You will still be responsible for paying your own attorneys’ fees.</span></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;margin-left: 24px;vertical-align: baseline"><span style="font-size: 14px; font-family: arial, helvetica, sans-serif;"><strong><span style="font-size: 14px; color: rgb(33, 37, 41);">I.<span style="font-size: 14px; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span></strong><strong><span style="font-size: 14px; color: rgb(33, 37, 41); border: 1px none windowtext; padding: 0px;">Procedure to Opt Out of Arbitration Provision.</span></strong><span style="font-size: 14px; color: rgb(33, 37, 41);">&nbsp;You may opt out of this arbitration provision only by written Notice by email to Legal@ghoststudio.net.&nbsp;<strong><span style="font-size: 14px; border: 1px none windowtext; padding: 0px;">You must send such Notice within thirty (30) days of your acceptance of these Terms of Service.</span></strong>&nbsp;You must sign and date the Notice, and include in it your name, address, and a clear statement that you do not wish to resolve disputes with GHOST STUDIO through arbitration. If you do not follow this procedure by your thirty (30) day deadline to do so, then you and GHOST STUDIO shall both be bound by the terms of this section entitled Dispute Resolution.</span></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;margin-left: 24px;vertical-align: baseline"><span style="font-size: 14px; font-family: arial, helvetica, sans-serif;"><strong><span style="font-size: 14px; color: rgb(33, 37, 41);">J.<span style="font-size: 14px; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span></strong><strong><span style="font-size: 14px; color: rgb(33, 37, 41); border: 1px none windowtext; padding: 0px;">Judicial</span></strong><strong><span style="font-size: 14px; color: rgb(33, 37, 41);">Forum for Disputes.&nbsp;</span></strong><span style="font-size: 14px; color: rgb(33, 37, 41);">If any portion of this section entitled "Dispute Resolution" is determined by a court to be inapplicable or invalid, then the remainder shall still be given full force and effect. Except as otherwise required by applicable law, in the event that the agreement to arbitrate is found not to apply to you or your claim, you and GHOST STUDIO agree that any judicial proceeding may only be brought in a court of competent jurisdiction in Hong Kong.&nbsp;Both you and GHOST STUDIO consent to venue and personal jurisdiction there.</span></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;margin-left: 24px;vertical-align: baseline"><span style="font-size: 14px; font-family: arial, helvetica, sans-serif;"><span style="font-size: 14px; color: rgb(33, 37, 41);"><br></span></span></p>
<p style="margin-top: 8px;margin-bottom: 8px;text-align: left;white-space: normal;vertical-align: middle"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;"><strong><span style="font-family: arial, helvetica, sans-serif; color: rgb(51, 51, 51);">Contact Us</span></strong></span></p>
<p style="margin-top: 8px; margin-bottom: 8px; text-align: left; white-space: normal; vertical-align: middle;"><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">If you have any questions or concerns about these Terms, you may contact us via the following address: </span><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px; text-decoration: underline;">Service@ghoststudio.net</span><span style="color: rgb(51, 51, 51); font-family: arial, helvetica, sans-serif; font-size: 14px;">.</span></p>
    </div>
  </div>
    `
  }

  render() {
    return <div dangerouslySetInnerHTML={{ __html: this.externalHTML }} />;
  }
}

export default ExternalHTMLRenderer;