import ErrorImg from 'assets/Icon/error.png';
import BaseButton from 'components/ui/button/BaseButton';
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

export default function ErrorPage() {
  const { t } = useTranslation()
  const navigate = useNavigate();
  const lang = useSelector(state=>{
    return state.user.language;
  });
  const homeNavigation = () => {
    let url = `/${lang}`;
    navigate(url);
  };
  return (
    <div className="error-container">
      <div className="error-container__inner">
        <div className="information-msg">
          <img src={ErrorImg} alt="error img" />
          <p>페이지를 찾을 수 없습니다.</p>
        </div>
        <BaseButton onClick={() => homeNavigation()} label="GO TO HOME" color="black" />
      </div>
    </div>
  )
}