import Dialog from '@mui/material/Dialog';
import { forwardRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import Slide from '@mui/material/Slide';
import store from "utils/store";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function MobileModal(props) {
  const { open, handleClose, data } = props;
  const { t } = useTranslation()
  return (
    <div>
      <Dialog
        fullScreen
        TransitionComponent={Transition}
        open={open}
        onClose={handleClose}
        className="mobile-dialog-wrapper"
        componentsProps={{ backdrop: { style: { backgroundColor: "rgba(0, 0, 0, 0.8)" } } }}
      >
        <button onClick={handleClose} className="close-btn"></button>
        <div className="mobile-dialog-container artist-wrapper">
          <div className="mobile-dialog-container__header">
          </div>
          <div className="mobile-dialog-image">
            <img src={store.awsFilePath(data?.desc?.image)} alt={`${data.title} thumbnail img`} />
          </div>
          <div className="mobile-dialog-inner">
            <div className="detail-info">
              <div className="detail-info__head">
                <div className="name-wrap">
                  <p>{data.title}</p>
                  <span>{data.desc.en_name}</span>
                </div>
                <div className="birthDate-wrap">
                  <p>{t("artist.detail.birthDate")}</p>
                  <span>{data.desc.birthday}</span>
                </div>
              </div>
              <div className="detail-info__body">
              {data.desc.filmography.map((item, index) => {
                return <div className="list-wrap" key={index}>
                  <p className="body-title">{item.category}</p>
                  <ul>
                    {item.list.map((itm, idx) => {
                      return <li key={idx} className="sub-desc">{itm.text}</li>
                    })}
                  </ul>
                </div>
              })}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

function DefaultModal(props) {
  const { open, handleClose, data } = props;
  const { t } = useTranslation()
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        className="base-dialog-wrapper grid-dialog-wrapper"
      >
        <div className="base-dialog-container artist-wrapper">
          <button onClick={handleClose} className="close-btn"></button>
          <div className="base-dialog-image">
            <img src={store.awsFilePath(data?.desc?.image)} alt={`${data.title} thumbnail img`} />
          </div>
          <div className="base-dialog-inner">
            <div className="detail-info">
              <div className="detail-info__head">
                <div className="name-wrap">
                  <p>{data.title}</p>
                  <span>{data.en_name}</span>
                </div>
                <div className="birthDate-wrap">
                  <p>{t("artist.detail.birthDate")}</p>
                  <span>{data.desc.birthday}</span>
                </div>
              </div>
              <div className="detail-info__body">
              {data.desc.filmography.map((item, index) => {
                return <div className="list-wrap" key={index}>
                  <p className="body-title">{item.category}</p>
                  <ul>
                    {item.list.map((itm, idx) => {
                      return <li key={idx} className="sub-desc">{itm.text}</li>
                    })}
                  </ul>
                </div>
              })}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default function ArtistDetailModal(props) {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
    {isWideScreen ? <MobileModal {...props} /> : <DefaultModal {...props} />}
    </>
    );
  }