import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { SET_LANGUAGE } from 'store/modules/user';
import { HashLink as Link } from 'react-router-hash-link';
import i18n from "locales/i18n";

import Logo from 'assets/Logo/logo.png';
import Logo_invert from 'assets/Logo/logo_invert.png';
import { GNB_ITEMS, SNS_LIST } from 'constants/gnbList';

function LanguageChange({closeMobileMenu}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLanguage = useSelector(state=>{
    return state.user.language;
  });
  const changeLanguage = (lang) => {
    if (lang !== userLanguage) {
      i18n.changeLanguage(lang);
      dispatch(SET_LANGUAGE(lang));
      navigate(`/${lang}`);
      closeMobileMenu()
    }
  };
  return (
    <ul className="lang-change-wrapp">
      <li><p className={`lang ${userLanguage === 'ko' ? 'isActive' : ''}`} onClick={() => changeLanguage("ko")}>KOR</p></li>
      <li><p className={`lang ${userLanguage === 'en' ? 'isActive' : ''}`} onClick={() => changeLanguage("en")}>ENG</p></li>
      <li><p className={`lang ${userLanguage === 'cn' ? 'isActive' : ''}`} onClick={() => changeLanguage("cn")}>CHN</p></li>
    </ul>
  )
}

function Header() {
  const initialState = GNB_ITEMS.reduce((acc, item) => {
    acc[item.name] = false;
    return acc;
  }, {});
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth <= 768);
  const [menuStates, setMenuStates] = useState(initialState);
  const [isMobileShow, setMobileIsShow] = useState(false);
  const [accordion, setAccordion] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation()
  const handleToggle = (index) => {
    if (accordion === index) {
      setAccordion(null);
    } else {
      setAccordion(index);
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const openMobileMenu = () => {
    setMobileIsShow(true)
    document.querySelector('html').style.overflow = 'hidden';
  }
  const closeMobileMenu = () => {
    setMobileIsShow(false)
    setAccordion(null);
    document.querySelector('html').style.overflow = 'auto';
  }

  const userLanguage = useSelector(state => {
    return state.user.language;
  });

  const hashNavigation = () => {
    setMenuStates(false)
    closeMobileMenu();
  };

  const handleNavigation = (item) => {
    let url = `/${userLanguage}`;
    if (item) {
      url = url + item;
    }
    navigate(url);
    setMenuStates(false)
    closeMobileMenu();
  };

  return (
    <>
      <header>
        <div className="nav-bar">
          <img className="logo-btn" onClick={() => handleNavigation()} src={Logo} alt="ghost studio logo" />
          <div className="nav-bar__inner">
            <ul className="gnb-container">
            {GNB_ITEMS.map((item, index) => {
              if (!(userLanguage === 'ko' && item.name === 'recruit') && !(userLanguage !== 'ko' && item.name === 'audition')) {
                return (
                  <li onMouseEnter={() => setMenuStates(prevState => ({ ...prevState, [item.name]: true }))}
                    onMouseLeave={() => setMenuStates(prevState => ({ ...prevState, [item.name]: false }))}
                    key={index} className={`gnb-item ${item.name} ${userLanguage}`}>
                    <p className="gnb-item-path">{t(`header.${[item.name]}`)}</p>
                    {menuStates[item.name] && (
                      <ul className={`children-menu ${userLanguage} ${item.name}`}>
                        {item.children.map((itm, idx) => {
                          if (item.name === 'ghostStudio') {
                            return <Link to={`/${userLanguage}/ghostStudio#${itm.hashName}`} className="children-menu-path" key={idx} onClick={() => hashNavigation()}><p>{t(`header.${[itm.name]}`)}</p></Link>;
                          } else {
                            return <li className="children-menu-path" key={idx} onClick={() => handleNavigation(`/${itm.pathName}`)}><p>{t(`header.${[itm.name]}`)}</p></li>;
                          }
                        })}
                      </ul>
                    )}
                  </li>
                );
              }
              return null;
            })}
            </ul>
            <div className="lang-change-wrapp-in">
              <LanguageChange closeMobileMenu={closeMobileMenu} />
            </div>
          </div>
          <button onClick={openMobileMenu} className="m-menu-open"></button>
        </div>
        <div className="lang-change-wrapp-out">
          <LanguageChange closeMobileMenu={closeMobileMenu} />
        </div>
      </header>
      {isWideScreen &&
        <div className={`m-nav-wrapper ${isMobileShow ? 'open' : 'close'}`}>
          <div className="m-nav-inner">
            <div className="m-nav-head">
              <img className="logo-btn" onClick={() => handleNavigation()} src={Logo_invert} alt="ghost studio white logo" />
              <button onClick={closeMobileMenu} className="m-menu-close"></button>
            </div>
            <div className="m-nav-body">
              <div className="gnb-container-wrapper">
                <ul className="gnb-container">
                  {GNB_ITEMS.map((item, index) => {
                    if (
                      !(userLanguage === 'ko' && item.name === 'recruit') && !(userLanguage !== 'ko' && item.name === 'audition')
                    ) {
                      return <li key={index} className="gnb-item">
                        <p className="gnb-item-path" onClick={() => handleToggle(index)}>{t(`header.${[item.name]}`)}</p>
                        <ul key={index} className={`children-container ${accordion === index ? 'show' : 'close'}`}>
                          {item.children.map((itm, idx) => {
                            if (item.name === 'ghostStudio') {
                              return <Link to={`/${userLanguage}/ghostStudio#${itm.hashName}`} className="children-item" key={idx} onClick={() => hashNavigation()}> <p className="children-item-path" onClick={() => handleNavigation(`/${itm.pathName}`)}>{t(`header.${[itm.name]}`)}</p></Link>;
                            } else {
                              return (<li className="children-item" key={idx}>
                                <p className="children-item-path" onClick={() => handleNavigation(`/${itm.pathName}`)}>{t(`header.${[itm.name]}`)}</p>
                              </li>)
                            }
                          })}
                        </ul>
                      </li>
                    }
                    return null;
                  })}
                </ul>
              </div>
              <div className="foot-container">
                <ul className="sns-list">
                  {SNS_LIST.map((item, index) => {
                    return <li onClick={() => {window.open(item.href)}} className={item.name} key={index}></li>
                  })}
                </ul>
                <LanguageChange closeMobileMenu={closeMobileMenu} />
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Header;