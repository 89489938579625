export const GNB_ITEMS = [
  {
    pathName: '/ghostStudio',
    name: 'ghostStudio',
    children: [
      {
        pathName: 'ghostStudio',
        hashName: 'company',
        name: 'ghostStudioNav.company',
      },
      {
        pathName: 'ghostStudio',
        hashName: 'history',
        name: 'ghostStudioNav.history',
      },
      {
        pathName: 'ghostStudio',
        hashName: 'office',
        name: 'ghostStudioNav.office',
      },
    ]
  },
  {
    pathName: '/business',
    name: 'business',
    children: [
      {
        pathName: 'games',
        name: 'businessNav.games',
      },
      {
        pathName: 'webtoons',
        name: 'businessNav.webtoons',
      },
      {
        pathName: 'dramas',
        name: 'businessNav.dramas',
      },
      {
        pathName: 'artists',
        name: 'businessNav.artists',
      },
    ]
  },
  {
    pathName: '/ir',
    name: 'ir',
    children: [
      {
        pathName: 'disclosure',
        name: 'irNav.disclosure',
      },
      {
        pathName: 'stock',
        name: 'irNav.stock',
      },
      {
        pathName: 'financial',
        name: 'irNav.financial',
      },
      {
        pathName: 'referenceRoom',
        name: 'irNav.referenceRoom',
      },
      {
        pathName: 'announcements',
        name: 'irNav.announcements',
      },
    ]
  },
  {
    pathName: '/pr',
    name: 'pr',
    children: [
      {
        pathName: 'news',
        name: 'prNav.news',
      },
    ]
  },
  // {
  //   pathName: '/audition',
  //   name: 'audition',
  //   children: [
  //     {
  //       pathName: 'audition',
  //       name: 'auditionNav.audition',
  //     },
  //   ]
  // },
  {
    pathName: '/recruit',
    name: 'recruit',
    children: [
      {
        pathName: 'recruit',
        name: 'recruitNav.recruit',
      },
    ]
  },
]
export const SNS_LIST = [
  {
    name: 'facebook',
    href: 'https://www.facebook.com/official.ghoststudio',
  },
  {
    name: 'twitter',
    href: 'https://twitter.com/goghoststudio',
  },
  {
    name: 'instagram',
    href: 'https://instagram.com/goghoststudio',
  },
]