import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import store from "../utils/store";
import Viewer from '@toast-ui/editor/dist/toastui-editor-viewer';
import '@toast-ui/editor/dist/toastui-editor-viewer.css';
import BannerLayout from "components/ui/banner/BannerLayout"
import Bg from "assets/Content/banner/pages/pr.png";
import MobileBg from "assets/Content/banner/pages/pr-m.png";
import { useTranslation } from 'react-i18next'

export default function IrDetail(props) {
  const { state } = props;
  const [data, setData] = useState({})
  const { index } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation()
  const bannerInfo = {
    title: `${state}.mainBannerTitle`,
    background: Bg,
    backgroundMobile: MobileBg,
    textColor: 'black',
    size: 'small'
  }
  const lang = useSelector(state=>{
    return state.user.language;
  });

  const goList = () => {
    let url = `/${lang}/${state}`;
    navigate(url, { state: state });
  };
  const prefix = "https://ghosthomeadmin-storage-c5898b6a153942-main.s3.ap-northeast-2.amazonaws.com/public/"

  useEffect(()=>{
    store.getPost(index)
    .then((data)=>{
      data.create_date = new Date(data?.create_date).toLocaleDateString().replace(/\./g, '').replace(/\s/g, '-')

      const pattern = /!\[\]\(([^)]*)\)+/g;
      data.body = JSON.parse(data.body).replace(pattern, '![]('+prefix+'$1)')
      setData(data)
      new Viewer({
        el: document.querySelector('#viewer'),
        initialValue: data.body
      });
    })
  }, [])
  return (
    <>
    {data &&
      <div className="ir-detail-container">
        <BannerLayout {...bannerInfo} />
        <div className="ir-detail-inner">
          <div className="content-layout">
            <button className="list-btn" onClick={() => goList()}><p>{t("details.back")}</p></button>
            <div className="content-layout__inner">
              <div className="content-layout__inner--head">
                <p>{data?.title}</p>
                <span>{data?.create_date}</span>
              </div>
              <div className="content-layout__inner--download">
                {data.file && <button onClick={() => {window.open(prefix+data.file)}}><span className="download-icon"></span><p>{data.file}</p></button>}
              </div>
              <div className="content-layout__inner--body">
                <div id="viewer"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
    </>
  )
}