import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useTranslation } from 'react-i18next'
import dompurify from "dompurify";
import store from "utils/store";
import ArtistDetailModal from "components/modal/ArtistDetail";
import BannerLayout from "components/ui/banner/BannerLayout"
import Bg from "assets/Content/banner/pages/artist.png";
import MobileBg from "assets/Content/banner/pages/artist-m.png";

const tabs = [
  {
    id: 'all',
    name: 'All',
  },
]


export function TabsLayout({data, lang}) {
  const [detailOpen, setDetailOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [selectedItem, setSelectedItem] = useState(null);

  const detailsModalOpen = (item) => {
    if (lang === 'ko') {
      setSelectedItem(item);
      setDetailOpen(true);
    }
  };
  const detailsModalClose = () => {
    setDetailOpen(false)
  };

  return (
    <div className="tabs-layout">
      <nav className="tab-header">
        <div className="tab-header__inner">
          { tabs.map((tab, index) => {
            return <div className={`tab-header-item ${selectedTab === tab ? 'isActive' : ''}`}
            key={index} onClick={() => setSelectedTab(tab)}><p>{tab.name}</p></div>
          }) }
        </div>
      </nav>
      <div className="tab-body artist">
        { data.map((item, index) => {
          return <div className="artist-item" key={index}>
            <div className="artist-item__inner" onClick={() => detailsModalOpen(item)}>
              <img className="thumbnail" src={item.image} alt={item.title} />
              <div className="info-text">
                <p className="name">{item.title}</p>
                {lang !== 'en' && <span className="type">{item.desc.en_name}</span>}
              </div>
            </div>
          </div>
        })}
      </div>
      {detailOpen &&
        <ArtistDetailModal open={detailOpen} handleClose={detailsModalClose} data={selectedItem} />
      }
    </div>
  );
}

function SlideLayout({imgList, loading}) {
  if (!loading && imgList && imgList?.length > 1) {
    return (
      <div className="artist-swiper-wrapper">
        <Swiper
          className="slider-component"
          modules={[Autoplay]}
          autoplay={{
            delay: 0,
            disableOnInteraction: false,
          }}
          speed={5000}
          loop={true}
          slidesPerView={"auto"}
        >
          {imgList.map((item, index) => (
            <SwiperSlide className="card-item" key={index}>
              <img src={item} alt="" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  } else {
    return null;
  }
}



export default function Artist() {
  const [data, setData] = useState([]);
  const [imgList, setImgList] = useState([]);
  const [loading, setLoading] = useState(null);
  const { t } = useTranslation()
  const sanitizer = dompurify.sanitize;
  const bannerInfo = {
    title: 'artist.mainBannerTitle',
    background: Bg,
    backgroundMobile: MobileBg,
    textColor: 'white'
  }
  const lang = useSelector(state=>{
    return state.user?.language;
  });

  useEffect(() => {
    const loacle = lang !== 'ko' && lang ? `_${lang}` : '';
    const content_id = '아티스트'+ loacle;
    setLoading(true)
    store.getBoardsByOrder(content_id, "DESC")
    .then(data => {
      let list = [];
      let img_list = [];
      data.forEach(element => {
        list.push({
          image: store.awsFilePath(element.thumbnail),
          title: element.title,
          desc: JSON.parse(element.body),
        })
        img_list?.push(store.awsFilePath(element?.thumbnail))
      });
      setImgList(img_list)
      setData(list)
      setLoading(false)
    })
  }, [lang]);
  return (
    <div className="artists-container">
      <BannerLayout {...bannerInfo} />
      <div className="artists-info">
        <div className="artists-info__inner">
          <p className="information-desc" dangerouslySetInnerHTML={{ __html: sanitizer(t("artist.information")) }}></p>
          <SlideLayout imgList={imgList} loading={loading} />
        </div>
      </div>
      <div className="artists-inner">
        <TabsLayout data={data} lang={lang} />
      </div>
    </div>
  )
}