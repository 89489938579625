import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import store from "utils/store";
import BannerLayout from "components/ui/banner/BannerLayout"
import Bg from "assets/Content/banner/pages/financial.png";
import MobileBg from "assets/Content/banner/pages/financial-m.png";

const tabs = [
  {
    id: 'balanceSheet',
    name: 'financial.tab.balanceSheet',
  },
  {
    id: 'incomeStatement',
    name: 'financial.tab.incomeStatement',
  },
]
function IncomeStatementTable() {
  const [topData, setTopData] = useState([]);
  const [bottomData, setBottomData] = useState([]);
  const { t } = useTranslation();
  const lang = useSelector(state=>{
    return state.user?.language;
  });
  useEffect(() => {
    const loacle = lang !== 'ko' && lang ? `_${lang}` : '';
    const content_id = '손익계산서'+ loacle;
    store.getBoards(content_id)
    .then(data => {
      let topList = []
      let bottomList = []
      data.forEach(element => {
        const datas = JSON.parse(element.body)
        topList = datas[0];
        bottomList = datas[1];
      });
      setTopData(topList)
      setBottomData(bottomList)
    })
  }, [lang]);

  return (
    <>
      <div className="table-wrapper">
        {topData.unit && <p className="unit">{t("financial.unit")} {topData.unit}</p>}
        <div className="table-inner">
          <div className="table-inner__head">
            <ul>
              {topData?.header?.map((item, index) => {
                return <li key={index}>{item}</li>
              })}
            </ul>
          </div>
          <div className="table-inner__body">
            {topData?.data?.map((item, index) => (
              <ul key={index}>
                {item?.map((value, innerIndex) => (
                  <li key={innerIndex}>{value}</li>
                ))}
              </ul>
            ))}
          </div>
        </div>
        {topData?.desc && <p className="caption">{topData?.desc}</p>}
      </div>
      <div className="table-wrapper">
      {bottomData.unit && <p className="unit">{t("financial.unit")} {bottomData.unit}</p>}
        <div className="table-inner">
          <div className="table-inner__head">
            <ul>
              {bottomData?.header?.map((item, index) => {
                return <li key={index}>{item}</li>
              })}
            </ul>
          </div>
          <div className="table-inner__body">
            {bottomData?.data?.map((item, index) => (
              <ul key={index}>
                {item?.map((value, innerIndex) => (
                  <li key={innerIndex}>{value}</li>
                ))}
              </ul>
            ))}
          </div>
        </div>
      {bottomData?.desc && <p className="caption">{bottomData?.desc}</p>}
      </div>
    </>
  )
}
function BalanceSheetTable() {
  const [topData, setTopData] = useState([]);
  const [bottomData, setBottomData] = useState([]);
  const { t } = useTranslation();
  const lang = useSelector(state=>{
    return state.user?.language;
  });
  useEffect(() => {
    const loacle = lang !== 'ko' && lang ? `_${lang}` : '';
    const content_id = '재무상태표'+ loacle;
    store.getBoards(content_id)
    .then(data => {
      let topList = []
      let bottomList = []
      data.forEach(element => {
        const datas = JSON.parse(element.body)
        topList = datas[0];
        bottomList = datas[1];
      });
      setTopData(topList)
      setBottomData(bottomList)
    })
  }, [lang]);

  return (
    <>
      <div className="table-wrapper">
        {topData.unit && <p className="unit">{t("financial.unit")} {topData.unit}</p>}
        <div className="table-inner">
          <div className="table-inner__head">
            <ul>
              {topData?.header?.map((item, index) => {
                return <li key={index}>{item}</li>
              })}
            </ul>
          </div>
          <div className="table-inner__body">
            {topData?.data?.map((item, index) => (
              <ul key={index}>
                {item?.map((value, innerIndex) => (
                  <li key={innerIndex}>{value}</li>
                ))}
              </ul>
            ))}
          </div>
        </div>
        {topData?.desc && <p className="caption">{topData?.desc}</p>}
      </div>
    <div className="table-wrapper">
      {bottomData.unit && <p className="unit">{t("financial.unit")} {bottomData.unit}</p>}
      <div className="table-inner">
        <div className="table-inner__head">
          <ul>
            {bottomData?.header?.map((item, index) => {
              return <li key={index}>{item}</li>
            })}
          </ul>
        </div>
        <div className="table-inner__body">
          {bottomData?.data?.map((item, index) => (
            <ul key={index}>
              {item?.map((value, innerIndex) => (
                value && <li key={innerIndex}>{value}</li>
              ))}
            </ul>
          ))}
        </div>
      </div>
      {bottomData?.desc && <p className="caption">{bottomData?.desc}</p>}
    </div>
    </>
  )
}

function TabsLayout() {
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const { t } = useTranslation()
  return (
    <div className="ir-tabs-layout">
      <nav className="ir-tab-header">
        <div className="ir-tab-header__inner">
          { tabs.map((tab, index) => {
            return <div className={`ir-tab-header-item ${selectedTab === tab ? 'isActive' : ''}`}
            key={index} onClick={() => setSelectedTab(tab)}><p>{t(tab.name)}</p></div>
          }) }
        </div>
      </nav>
      <div className="ir-tab-body">
        {selectedTab.id === 'balanceSheet' ? <BalanceSheetTable /> : selectedTab.id === 'incomeStatement' ? <IncomeStatementTable /> : null}

      </div>
    </div>
  );
}

export default function Disclosure() {
  const bannerInfo = {
    title: 'financial.mainBannerTitle',
    background: Bg,
    backgroundMobile: MobileBg,
    textColor: 'black',
    size: 'small'
  }
  return (
    <div className="financial-container">
      <BannerLayout {...bannerInfo} />
      <div className="financial-inner">
        <TabsLayout />
      </div>
    </div>
  )
}