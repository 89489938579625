import {
    listGhostBoardLists,
    ghostBoardsByGhostboardlistID,
    getGhostBoard,
    ByCreateDate,
    ByOrder,
    ByTitle,
} from '../graphql/queries';
import { API } from 'aws-amplify';
import { Storage } from 'aws-amplify';
import md5 from 'md5';

const boardList = async (boardName)=> {
    const allGhostBoards = await API.graphql({
        query: listGhostBoardLists,
        variables: {
            filter: {
                board_name: {eq: boardName}
            }
        }
    });

    return allGhostBoards.data.listGhostBoardLists.items[0]
}

const getBoards = async (boardName, limit)=> {
    let v = {
        ghostboardlistID: boardName
    }
    if (limit) {
        v.limit = limit
    }
    const allGhostBoards = await API.graphql({
        query: ghostBoardsByGhostboardlistID,
        variables: v
    });
    return allGhostBoards.data.ghostBoardsByGhostboardlistID.items
}
/* */
const getBoardsByCreateDate = async (boardName, sortDirection, limit, nextToken)=> {
    let v = {
        ghostboardlistID: boardName,
        sortDirection: sortDirection,
        filter: {
            is_public: {eq: true}
        }
    }
    if (limit) {
        v.limit = limit
    }
    if (typeof nextToken !== "undefined") {
        v.nextToken = nextToken;
    }
    const allGhostBoards = await API.graphql({
        query: ByCreateDate,
        variables: v
    });
    const nextPageToken = allGhostBoards.data.ByCreateDate.nextToken;
    return nextToken || nextToken === null ? {
        items: allGhostBoards.data.ByCreateDate.items,
        nextToken: nextPageToken
    } : allGhostBoards.data.ByCreateDate.items;
}

const getBoardsByOrder = async (boardName, sortDirection, limit)=> {
    let v = {
        ghostboardlistID: boardName,
        sortDirection: sortDirection,
        filter: {
            is_public: {eq: true}
        }
    }
    if (limit) {
        v.limit = limit
    }
    const allGhostBoards = await API.graphql({
        query: ByOrder,
        variables: v
    });
    return allGhostBoards.data.ByOrder.items
}

const getBoardsByTitle = async (boardName, sortDirection, limit)=> {
    let v = {
        ghostboardlistID: boardName,
        sortDirection: sortDirection,
        filter: {
            is_public: {eq: true}
        }
    }
    if (limit) {
        v.limit = limit
    }
    const allGhostBoards = await API.graphql({
        query: ByTitle,
        variables: v
    });
    return allGhostBoards.data.ByTitle.items
}

const getPost = async (postID)=> {
    const ghostPost = await API.graphql({
        query: getGhostBoard,
        variables: {
            id: postID
        }
    });
    const data = ghostPost.data.getGhostBoard

    return data
}

const getImage = async (key) => {
    if (key !== null && key !== "") {
        return await Storage.get(key, {
            level: 'public',
            download: false
        })
    }
    return
}

const fileUpload = async (file) => {
    let filename = md5(new Date().getTime()+file.name)
    let t = new Promise((resolve, reject) => {
        Storage.put(filename, file, {
            resumable: true,
            completeCallback: (event) => {
                resolve(event.key)
            },
            progressCallback: (progress) => {
                console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            },
            errorCallback: (err) => {
                reject(err)
            }
        })
    })
    return await t
}

const fileUploadFileName = async (file) => {
    let t = new Promise((resolve, reject) => {
        Storage.put(file.name, file, {
            resumable: true,
            completeCallback: (event) => {
                resolve(event.key)
            },
            progressCallback: (progress) => {
                console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            },
            errorCallback: (err) => {
                reject(err)
            }
        })
    })
    return await t
}

const prefix = "https://ghosthomeadmin-storage-c5898b6a153942-main.s3.ap-northeast-2.amazonaws.com/public/"
const awsFilePath = (src) => {
    return prefix+src
}

const body = {
    getPost,
    boardList,
    getBoards,
    getImage,
    fileUpload,
    fileUploadFileName,
    getBoardsByCreateDate,
    getBoardsByOrder,
    getBoardsByTitle,
    awsFilePath,
}

export default body