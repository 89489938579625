import { createSlice } from '@reduxjs/toolkit';
import { setCookie, getCookie } from 'utils/cookie';
import Locale from '../lo.json';
const initialState = {
  language: '',
};
const userSlice = createSlice({
  // createAction 과 createReducer의 결합임.
  // 슬라이스이름. 이것을 기반으로 액션이름이 자동 생성됨.
  // 슬라이스이름/리듀서이름으로 액션이름이 결정됨.
  name: 'user',
  initialState,
  reducers: {
    SET_LANGUAGE: (state, action) => {
      if (getCookie('userCookie') === "true") {
        setCookie('userCountry', action.payload);
      }
      state.language = action.payload;
    },
    GET_LANGUAGE: (state) => {
      let path = window.location?.pathname?.split("/");
      if (path[0] === 'en' || path[1] === 'en') {
        state.language = "en";
        return;
      } else if (path[0] === 'ko' || path[1] === 'ko') {
        state.language = "ko";
        return;
      } else if (path[0] === 'cn' || path[1] === 'cn') {
        state.language = "cn";
        return;
      }
      if (getCookie('userCookie') === "true") {
        const userLanguage = getCookie('userCountry') || Locale;
        state.language = userLanguage;
      } else {
        state.language = Locale;
      }
    },
  },
});

export default userSlice.reducer;
export const getUserInfo = (state) => state.user;
export const { SET_LANGUAGE, GET_LANGUAGE } = userSlice.actions;