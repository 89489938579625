import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import { EffectCoverflow, Pagination } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import GameSubBannerImg from 'assets/Content/banner/pages/game-detail.png'
import Dialog from '@mui/material/Dialog';
import dompurify from "dompurify";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import store from "../utils/store";
import '@toast-ui/editor/dist/toastui-editor-viewer.css';

import BannerLayout from "components/ui/banner/BannerLayout"

function SubBanner() {
  const navigate = useNavigate();
  const lang = useSelector(state=>{
    return state.user.language;
  });
  const { t } = useTranslation()
  const sanitizer = dompurify.sanitize;
  return (
    <div className="game-sub-banner">
      <div className="game-sub-banner__inner">
        <div className="banner-content">
          <p className="title-text" dangerouslySetInnerHTML={{ __html: sanitizer(t("games.detailBanner.title")) }}></p>
          <button onClick={() => navigate(`/${lang}/news`)} className="btn">{t("games.detailBanner.buttonText")}</button>
        </div>
        <img className="banner-item" src={GameSubBannerImg} alt="Game Sub Banner Img 뉴스 바로가기" />
      </div>
    </div>
  )
}

function GameVideoModal(props) {
  const { open, handleClose, data } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        className="video-dialog-wrapper"
      >
      <button onClick={handleClose} className="close-btn"></button>
        <div className="video-dialog-container">
          <div className="video-dialog-container__inner">
            <iframe src={data} title="Slots-Classic Vegas Quick Look-Google play"></iframe>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

function GameMediaSlide({list}) {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth <= 1280);
  const [detailOpen, setDetailOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const prefix = "https://ghosthomeadmin-storage-c5898b6a153942-main.s3.ap-northeast-2.amazonaws.com/public/"

  const itemLengthLoop = list?.length < 3 ? false : true;
  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth <= 1280);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const detailsModalOpen = (item) => {
    if (!item) return;
    setSelectedItem(item);
    setDetailOpen(true);
  };
  const detailsModalClose = () => {
    setDetailOpen(false)
  };
  return (
    <>
      {list &&
    <div className={`games-media-slide-wrapper ${list.length < 1 ? 'none' : ''}`}>
     {isWideScreen ?
      <Swiper
      className="games-media-slide-inner"
      modules={[Pagination]}
      slidesPerView={1}
      centeredSlides={true}
      speed={700}
      loop={true}
      loopedSlides={1}
      loopAdditionalSlides={1}
      pagination={{
          el: '.swiper-pagination-custom',
        "clickable": true
      }}
      slideToClickedSlide={true}>
      {list?.map((item, index) => (
        <SwiperSlide key={index}>
          <div className="inner">
            <img onClick={() => detailsModalOpen(item.link)} className={item.link ? 'pointer' : ''} src={prefix+item.image} alt="img" />
          </div>
        </SwiperSlide>
      ))}
      </Swiper> :
      <Swiper
        className="games-media-slide-inner"
        effect="coverflow"
        coverflowEffect={{
          rotate: 0, // 슬라이더 회전 각 : 클수록 슬라이딩시 회전이 커짐
          stretch: -200, // 슬라이더간 거리(픽셀) : 클수록 슬라이더가 서로 많이 겹침
          depth: 250, // 깊이 효과값 : 클수록 멀리있는 느낌이 강해짐
          modifier: 1, // 효과 배수 : 위 숫자값들에 이 값을 곱하기 처리하여 효과를 강하게 처리함
          slideShadows: false, // 슬라이더 그림자 : 3D 효과를 강조하기 위한 회전시 흐릿한 효과
        }}
        modules={[Pagination, EffectCoverflow]}
        slidesPerView={3}
        centeredSlides={true}
        speed={700}
        loop={itemLengthLoop}
        loopedSlides={3}
        loopAdditionalSlides={1}
        slideToClickedSlide={true}
        pagination={{
            el: '.swiper-pagination-custom',
          "clickable": true
        }}
      >

      {list?.map((item, index) => (
        <SwiperSlide key={index}>
          <div className="inner">
            <img onClick={() => detailsModalOpen(item.link)} className={item.link ? 'pointer' : ''} src={prefix+item.image} alt="img" />
          </div>
        </SwiperSlide>
      ))}

      </Swiper>
      }
      <div className="swiper-pagination-custom"></div>
      <GameVideoModal open={detailOpen} handleClose={detailsModalClose} data={selectedItem} />
    </div>
      }
    </>
  );
};

export default function GamesDetail() {
  const [data, setData] = useState({})
  const { id } = useParams();
  useEffect(()=>{
    store.getPost(id)
    .then((data)=>{
      const prefix = "https://ghosthomeadmin-storage-c5898b6a153942-main.s3.ap-northeast-2.amazonaws.com/public/"
      data.body = JSON.parse(data.body);
      data.body.gameImage = prefix + data?.body?.gameImage
      data.body.gameBennerMobile = prefix + data?.body?.gameBennerMobile
      data.body.gameBennerPC = prefix + data?.body?.gameBennerPC
      data.body.gameIcon = prefix + data?.body?.gameIcon
      data.body.store.apk = data?.body?.store?.apk?prefix + data?.body?.store?.apk:null
      setData({body: data.body, title: data.title})
    })
  }, [])

  const bannerInfo = {
    titleImage: data?.body?.gameIcon,
    background: data?.body?.gameBennerPC,
    backgroundMobile: data?.body?.gameBennerMobile,
  }
// diraction horizontal-> 가로 vertical -> 세로

  return (
    <div className="games-detail-container">
      <BannerLayout {...bannerInfo} />
      <div className="games-detail-inner">
        <div className="games-content">
          <div className="games-content__inner">
            <div className={`games-content__phoneImage ${data.body?.diraction}`}>
              {data.body?.diraction === 'h' &&
                (<img className="row-image" src={data?.body?.gameImage} alt="phone game img" />)}
              {data.body?.diraction === 'v' &&
                (<img className="column-image" src={data?.body?.gameImage} alt="phone game img" />)}

              </div>
              <div className="games-info">
                <div className="games-info__desc">
                  <p className="gameName">{data.body?.fullname}</p>
                  <span className="gameDesc">{data.body?.description}</span>
                </div>
                <ul className="games-info__link">
                  {data.body?.store.google && <li onClick={() => {window.open(data.body?.store.google)}} className={`link-item google`}></li>}
                  {data.body?.store.apple && <li onClick={() => {window.open(data.body?.store.apple)}} className={`link-item apple`}></li>}
                  {data.body?.store.facebook && <li onClick={() => {window.open(data.body?.store.facebook)}} className={`link-item facebook`}></li>}
                  {(data.body?.store.apk && data.body?.store.apk !== "") && <li onClick={() => {window.open(data.body?.store.apk)}} className={`link-item apk`}></li>}
                  {data.body?.store.web && <li onClick={() => {window.open(data.body?.store.web)}} className={`link-item website`}></li>}
                </ul>
              </div>
          </div>
        </div>
        <GameMediaSlide list={data.body?.contents} />
        <SubBanner />
      </div>
    </div>
  )
}