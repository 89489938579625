import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'
import BannerLayout from "components/ui/banner/BannerLayout"
import Bg from "assets/Content/banner/pages/stock.png";
import MobileBg from "assets/Content/banner/pages/stock-m.png";
import { API } from 'aws-amplify';

function TopTableLayout(props) {
  const { current } = props;
  const { t } = useTranslation()
  if (!current) {
    return <></>
  }
  const v = (key)=>{
    try {return current.results[0][key];} catch (error) {}
  }
  const updown = ()=>{
    try {switch (current.results[0]["F15006"]) {
      case "1": case "2": case "6": case "7": return "up"
      case "4": case "5": case "8": case "9": return "down"
    }
    return "";} catch (error) {}
  }
  return (
    <div className="top-table-layout">
      <p className="table-layout-title">{t("stock.tableTitle.currentPriceQuote")}</p>
      <div className="top-table-layout__inner">
        <div className="current-container">
          <div className="current-container__state">
            <div className="current-price">
              <p className={`current-price__number ${updown()}`}>{v("F15001")}</p>
            </div>
            <div className="current-info">
              <div className="current-info__item">
                <p className="title">{t("stock.tableHead.changeFromPreviousDay")}</p>
                <p className={`number number-icon ${updown()}`}>{v("F15472")}</p>
              </div>
              <div className="current-info__item">
                <p className="title">{t("stock.tableHead.percentageChange")}</p>
                <p className={`number ${updown()}`}>{updown() === 'up' ? "+"+v("F15004") : v("F15004")}%</p>
              </div>
            </div>
          </div>
          <table className="current-table-wrapper">
            <colgroup>
              <col className="th" />
              <col className="td" />
              <col className="th" />
              <col className="td" />
            </colgroup>
            <thead className="current-table-wrapper__head">
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody className="current-table-wrapper__body desktop">
              <tr>
                <th><p>{t("stock.tableHead.openingPrice")}</p></th>
                <td className={`number ${updown()}`}><p>{v("F15009")}</p></td>
                <th><p>{t("stock.tableHead.lowPrice")}</p></th>
                <td className={`number ${updown()}`}><p>{v("F15011")}</p></td>
              </tr>
              <tr>
                <th><p>{t("stock.tableHead.highPrice")}</p></th>
                <td className={`number ${updown()}`}><p>{v("F15010")}</p></td>
                <th><p>{t("stock.tableHead.tradingVolume")}</p></th>
                <td><p>{v("F15015")}</p></td>
              </tr>
            </tbody>
            <tbody className="current-table-wrapper__body mobile">
              <tr>
                <th><p>{t("stock.tableHead.openingPrice")}</p></th>
                <td className={`number ${updown()}`}><p>{v("F15009")}</p></td>
              </tr>
              <tr>
                <th><p>{t("stock.tableHead.lowPrice")}</p></th>
                <td className={`number ${updown()}`}><p>{v("F15011")}</p></td>
              </tr>
              <tr>
                <th><p>{t("stock.tableHead.highPrice")}</p></th>
                <td className={`number ${updown()}`}><p>{v("F15010")}</p></td>
              </tr>
              <tr>
                <th><p>{t("stock.tableHead.tradingVolume")}</p></th>
                <td><p>{v("F15015")}</p></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="details-container">
          <table className="details-table-wrapper desktop">
            <tbody className="details-table-wrapper__body">
              <tr>
                <th><p>{t("stock.tableHead.previousClosingPrice")}</p></th>
                <td><p>{v("F15007")}</p></td>
                <th><p>{t("stock.tableHead.52WeekLow")}</p></th>
                <td><p>{v("F02133")}</p></td>
              </tr>
              <tr>
                <th><p>{t("stock.tableHead.listedStockPrice")}</p></th>
                <td><p>{v("F16143")}</p></td>
                <th><p>{t("stock.tableHead.52WeekHigh")}</p></th>
                <td><p>{v("F02155")}</p></td>
              </tr>
              <tr>
                <th><p>{t("stock.tableHead.upperLimitPrice")}</p></th>
                <td><p>{v("F16117")}</p></td>
                <th><p>{t("stock.tableHead.openingPrice")}</p></th>
                <td><p>{v("F15009")}</p></td>
              </tr>
              <tr>
                <th><p>{t("stock.tableHead.lowerLimitPrice")}</p></th>
                <td><p>{v("F16118")}</p></td>
                <th><p>{t("stock.tableHead.highPrice")}</p></th>
                <td><p>{v("F15010")}</p></td>
              </tr>
              <tr>
                <th><p>{t("stock.tableHead.tradingValue")}</p></th>
                <td><p>{v("F15023")}</p></td>
                <th><p>{t("stock.tableHead.lowPrice")}</p></th>
                <td><p>{v("F15011")}</p></td>
              </tr>
              <tr>
                <th><p>{t("stock.tableHead.faceValue")}</p></th>
                <td><p>{v("F16046")}</p></td>
                <th><p>{t("stock.tableHead.tradingVolume")}</p></th>
                <td><p>{v("F15015")}</p></td>
              </tr>
            </tbody>
          </table>
          <table className="details-table-wrapper mobile">
            <tbody className="details-table-wrapper__body">
              <tr>
                <th><p>{t("stock.tableHead.previousClosingPrice")}</p></th>
                <td><p>{v("F15007")}</p></td>
              </tr>
              <tr>
                <th><p>{t("stock.tableHead.52WeekLow")}</p></th>
                <td><p>{v("F02133")}</p></td>
              </tr>
              <tr>
                <th><p>{t("stock.tableHead.listedStockPrice")}</p></th>
                <td><p>{v("F16143")}</p></td>
              </tr>
              <tr>
                <th><p>{t("stock.tableHead.52WeekHigh")}</p></th>
                <td><p>{v("F02155")}</p></td>
              </tr>
              <tr>
                <th><p>{t("stock.tableHead.upperLimitPrice")}</p></th>
                <td><p>{v("F16117")}</p></td>
              </tr>
              <tr>
                <th><p>{t("stock.tableHead.openingPrice")}</p></th>
                <td><p>{v("F15009")}</p></td>
              </tr>
              <tr>
                <th><p>{t("stock.tableHead.lowerLimitPrice")}</p></th>
                <td><p>{v("F16118")}</p></td>
              </tr>
              <tr>
                <th><p>{t("stock.tableHead.highPrice")}</p></th>
                <td><p>{v("F15010")}</p></td>
              </tr>
              <tr>
                <th><p>{t("stock.tableHead.tradingValue")}</p></th>
                <td><p>{v("F15023")}</p></td>
              </tr>
              <tr>
                <th><p>{t("stock.tableHead.lowPrice")}</p></th>
                <td><p>{v("F15011")}</p></td>
              </tr>
              <tr>
                <th><p>{t("stock.tableHead.faceValue")}</p></th>
                <td><p>{v("F16046")}</p></td>
              </tr>
              <tr>
                <th><p>{t("stock.tableHead.tradingVolume")}</p></th>
                <td><p>{v("F15015")}</p></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

function BodyTableLayout(props) {
  const { t } = useTranslation()
  const { history } = props;
  if (!history) {
    return <></>
  }
  const tableHead = [
    'date',
    'closingPrice',
    'changeAmount',
    'percentageChange',
    'openingPrice',
    'highPrice',
    'lowPrice',
    'tradingVolume'
  ]
  const v = (i, key)=>{
    try {return history.results[i][key];} catch (error) {}
  }
  const updown = (i)=>{
    try {switch (history.results[i]["F15006"]) {
      case "1": case "2": case "6": case "7": return "up"
      case "4": case "5": case "8": case "9": return "down"
    }
    return "";} catch (error) {}
  }

  const tableBody = history?.results?.map((d, i)=>{
    return {
      date: v(i, "F12507"),
      closingPrice: v(i, "F15001"),
      changeAmount: {
        number: v(i, "F15472"),
        state: updown(i)
      },
      percentageChange: {
        number: v(i, "F15004"),
        state: updown(i)
      },
      openingPrice: v(i, "F15009"),
      highPrice: v(i, "F15010"),
      lowPrice: v(i, "F15011"),
      tradingVolume: v(i, "F15015"),
    }
  })
  return (
    <div className="bottom-table-layout">
      <p className="table-layout-title">{t("stock.tableTitle.dailyQuotes")}</p>
      <table className="list-table-wrapper">
        <thead className="list-table-wrapper__head">
          <tr>
            {tableHead.map((head, index) => {
              return <th key={index} className={head}>{t(`stock.tableHead.${[head]}`)}</th>
            })}
          </tr>
        </thead>
        <tbody className="list-table-wrapper__body">
          {tableBody.map((body, index) => {
            return <tr key={index}>
              <td><p>{body.date}</p></td>
              <td><p>{body.closingPrice}</p></td>
              <td><p className={`number-icon ${body.changeAmount.state}`}>{body.changeAmount.number}</p></td>
              <td><p className={`${body.percentageChange.state}`}>{body.percentageChange.state === 'up' ? '+'+body.percentageChange.number : body.percentageChange.number}%</p></td>
              <td><p>{body.openingPrice}</p></td>
              <td><p>{body.highPrice}</p></td>
              <td><p>{body.lowPrice}</p></td>
              <td><p>{body.tradingVolume}</p></td>
            </tr>
          })}
        </tbody>
      </table>
    </div>
  )
}

export default function Stock() {
  const lang = useSelector(state=>{
    return state.user.language;
  });
  const [stockInfo, setStockInfo] = useState({})

  useEffect(() => {
    API.get('api85851ce2', '/stockinfo?func=marketData&lang='+lang).then((data)=>setStockInfo(data))
  }, []);

  const bannerInfo = {
    title: 'stock.mainBannerTitle',
    background: Bg,
    backgroundMobile: MobileBg,
    textColor: 'black',
    size: 'small'
  }
  return (
    <div className="stock-container">
      <BannerLayout {...bannerInfo} />
      <div className="stock-inner">
        <section className="section-layout">
          <TopTableLayout current={stockInfo?.current} />
          <BodyTableLayout history={stockInfo?.history} />
        </section>
      </div>
    </div>
  )
}

/*

종목 일별정보 (getStockHist)
https://asp.koscom.co.kr/listservice/getStockHist
F12507 입회일자 YY/MM/DD
F15001 현재가 정수형(천단위 콤마)
F15006 등락구분 (1:상한/2:상승/3:보합/4:하한/5:하락/6:기세상한/7:기세상승/8:기세하한/9:기세하락)
F15472 대비 정수형(천단위 콤마)
F15004 등락율 실수형(소수점 두번째자리)
F15009 시가 정수형(천단위 콤마)
F15010 고가 정수형(천단위 콤마)
F15011 저가 정수형(천단위 콤마)
F15015 동일거래량 정수형(천단위 콤마)
F15023 동일거래금액 정수형(천단위 콤마)
F15028 시가총액 정수형(천단위 콤마)
F15029 시가총액비중 실수형(소수점 두번째자리)
F16143 상장주식수 정수형(천단위 콤마)

{"F12507":"23/10/13","F15001":"10,820","F15472":"20","F15004":"0.19","F15006":"2","F15009":"10,780","F15010":"10,850","F15011":"10,620","F15015":"10,542",
"F15023":"113,043,580","F15028":"146,934,431,440","F15029":"0.04","F16143":"13,579,892"},


https://asp.koscom.co.kr/listservice/getStockBasic
종목 정보 (getStockBasic)
항목명 설명 비고
{v("F16002")} 한글종목명 gubun=K
{v("F16004")} 영문종목명 gubun=E
{v("F15001")} 현재가 정수형(천단위 콤마) ; 현재가 시세
{v("F15006")} 등락구분 (1:상한/2:상승/3:보합/4:하한/5:하락/6:기세상한/7:기세상승/8:기세하한/9:기세하락) ; 전일대비
{v("F15472")} 대비 정수형(천단위 콤마); 전일대비
{v("F15004")} 등락율 실수형(소수점 두번째자리) ; 등락률
{v("F15009")} 시가 정수형(천단위 콤마) ; 시가
{v("F15010")} 고가 정수형(천단위 콤마) ; 고가
{v("F15011")} 저가 정수형(천단위 콤마) ; 저가
{v("F15015")} 거래량 정수형(천단위 콤마) ; 거래량

{v("F15007")} 기준가 정수형(천단위 콤마) ; 전일가
{v("F16143")} 상장주식수 정수형(천단위 콤마) ; 상장주식가
{v("F16117")} 상한가 정수형(천단위 콤마) ; 상한가
{v("F16118")} 하한가 정수형(천단위 콤마) ; 하한가
{v("F15023")} 거래대금 정수형(천단위 콤마) ; 거래대금
{v("F16046")} 액면가 정수형(천단위 콤마) ; 액면가
{v("F02133")} 52최고가 정수형(천단위 콤마) ; 52주 최고
{v("F02155")} 52최저가 정수형(천단위 콤마) ; 52주 최저

{
    "success": true,
    "results": [
        {
            "F16004": "ME2ZEN",
            "F15007": "10,800", // 전일가
            "F15006": "2",
            "F15001": "10,820",
            "F15004": "0.19",
            "F15009": "10,780",
            "F15010": "10,850",
            "F15011": "10,620",
            "F15015": "10,542",
            "F15028": "146,934,431,440",
            "F15472": "20",
            "F02133": "14,850",
            "F02155": "9,710",
            "F16117": "14,040",
            "F16118": "7,560",
            "F16143": "13,579,892",
            "F13058": "0.00",
            "F16046": "0",
            "F15023": "113,043,580",
            "F11505": "31000000",
            "F06023": "15.18",
            "F03377": "2,061,501",
            "F16052": "0",
            "UPDATE_TIME": "2023-10-13 17:59:59"
        }
    ]
}
*/