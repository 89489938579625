// import store from "utils/store";
import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'
import BannerLayout from "components/ui/banner/BannerLayout"
import Bg from "assets/Content/banner/pages/pr.png";
import MobileBg from "assets/Content/banner/pages/pr-m.png";
import { API } from 'aws-amplify';
import Skeleton from '@mui/material/Skeleton';

function TableComponent() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation()
  const numberOfRows = 10;
  const columns = 5;
  const skeletonRows = new Array(numberOfRows).fill(null);

  const lang = useSelector(state=>{
    return state.user.language;
  });

  useEffect(() => {
    API.get('api85851ce2', '/stockinfo?func=disclosure&lang='+lang)
    .then((data)=>{
      let list = data.dataList.map((d)=>{
        return {
          desc: {
            link: d.disclsViewerLink,
            publicly: d.comAbbrv,
            submitter: d.submitOblgNm,
          },
          date: d.disclsPublDdtm,
          title: d.formKorNm?d.formKorNm:d.formEngNm,
        }
      })
      setData(list)
      setLoading(false)
    })
  }, [lang]);
  const tableHead = [
    'number',
    'disclosureTargetCompany',
    'date',
    'reportName',
    'submitter'
  ]
  return (
    <div className="table-wrapper default-wrapper">
      <table className="table-inner">
        <colgroup>
          {tableHead.map((head, index) => {
            return <col key={index} className={head} />
          })}
        </colgroup>
        <thead className="table-inner__head">
          <tr>
          {tableHead.map((head, index) => {
            return <th key={index} className={head}>{t(`disclosure.tableHead.${[head]}`)}</th>
          })}
          </tr>
        </thead>
        {loading ?
          <tbody className="table-inner__body skeleton-rows">
          {skeletonRows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {new Array(columns).fill(null).map((column, columnIndex) => (
                <td key={columnIndex} className={`${columnIndex === 3 ? 'reportName' : ''}`}>
                  <div className="desktop">
                    <Skeleton className="default" animation="wave" />
                  </div>
                  <div className="mobile">
                    <Skeleton className="medium" animation="wave" />
                    <Skeleton className="small" animation="wave" />
                  </div>
                </td>
              ))}
            </tr>
          ))}
          </tbody> :
          <tbody className="table-inner__body">
              {data.map((body, index) => {
                return <tr key={index}>
                  <td><p>{index+1}</p></td>
                  <td><p>{body.desc.publicly}</p></td>
                  <td><p>{body.date}</p></td>
                  <td className="reportName" onClick={() => {window.open(body.desc.link)}}><div className="reportName__inner"><p>{body.title}</p><span className="link-icon"></span></div><span className="date">{body.date}</span></td>
                  <td><p>{body.desc.submitter}</p></td>
                </tr>
              })}
          </tbody>}
      </table>
    </div>
  )
}

export default function Disclosure() {
  const bannerInfo = {
    title: 'disclosure.mainBannerTitle',
    background: Bg,
    backgroundMobile: MobileBg,
    textColor: 'black',
    size: 'small'
  }
  return (
    <div className="disclosure-container">
      <BannerLayout {...bannerInfo} />
      <div className="disclosure-inner">
        <section className="section-layout">
          <TableComponent />
        </section>
      </div>
    </div>
  )
}