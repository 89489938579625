import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { setCookie, getCookie } from 'utils/cookie';

export default function CookieBanner() {
  const [useCookie, setUseCookie] = useState(getCookie('userCookie'))
  const navigate = useNavigate();
  const userLanguage = useSelector(state => {
    return state.user.language;
  });
  const useCookieFn = () => {
    setUseCookie("true")
  }
  useEffect(() => {
    if (useCookie) {
      setCookie('userCookie', useCookie)
    }
  }, [useCookie])
  if (useCookie === "true") {
    return <></>
  }
  return (
    <div className="cookie-banner-wrapper">
      <div className="cookie-banner-inner">
        <p className="cookie-desc">We use cookies to collect and analyse information on site performance and usage, to enhance and customize content, and for marketing purposes. By clicking "OK" or by clicking into any content on this site, you agree to allow cookies to be placed. To find out more or to change your cookie settings, visit the cookies section of our <span onClick={() => navigate(`/${userLanguage}/legal/PrivacyPolicy`)}>privacy policy.</span></p>
        <button onClick={useCookieFn} className="cookie-btn">OK</button>
      </div>
    </div>
  )
}
