import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import store from '../utils/store';
import BannerLayout from "components/ui/banner/BannerLayout"
import Bg from "assets/Content/banner/pages/news.png";
import MobileBg from "assets/Content/banner/pages/news-m.png";
// import Pagination from '@mui/material/Pagination';


function NewsLayoutItem(props) {
  const { item } = props;
  const navigate = useNavigate();
  const lang = useSelector(state=>{
    return state.user.language;
  });
  const detailNavigation = (index) => {
    let url = `/${lang}/news`;
    if (index) {
      url = url + `/${index}`;
    }
    navigate(url, { state: "news" });
  };
  return (
    <div onClick={() => detailNavigation(item?.id)} className="news-item">
      <div className="news-item__image" style={{ backgroundImage: `url(${item?.image})` }}></div>
      <div className="news-item__inner">
        <div className="head-info">
          <div className="head-info__title"><p>{item?.title}</p></div>
          <span className="head-info__date">{item?.date}</span>
        </div>
        <p className="foot-info">{item?.desc}</p>
      </div>
    </div>
  )
}

export default function News() {
  const { t } = useTranslation()
  // 한번에 이동할 페이지당 게시글수
  const pageUnit = 10
  // 지금 있는 페이지 시작은 0
  const [pageIndex, setPageIndex] = useState(0);
  // 각 페이지 index별로 token 저장 시작은 null
  const [pageTokenList, setPageTokenList] = useState([null]);
  const [newsList, setNewsList] = useState([]);
  const lang = useSelector(state=>{
    return state.user.language;
  });

  useEffect(() => {
    const pattern = /!\[\][^\n]*\n+/g;
    const loacle = lang !== 'ko' && lang ? `_${lang}` : '';
    const content_id = '공지사항'+ loacle;
    // 시작시에도 같은 함수로 동작하도록 시작에 null을 넣음
    store.getBoardsByCreateDate(content_id, "DESC", pageUnit, pageTokenList[pageIndex])
    .then(d => {
      let list = []
      // 다음 페이지의 토큰이없는 경우에만 다음 페이지 토큰을 추가
      // 마지막 페이지에선 next 함수가 동작하지 않으므로 추가되지 않음
      if (!pageTokenList[pageIndex+1] && d.nextToken) {
        setPageTokenList(pageTokenList.concat([d.nextToken]))
      }
      d.items.forEach(element => {
        list.push({
          id: element.id,
          image: store.awsFilePath(element.thumbnail),
          title: element.title,
          date: new Date(element.create_date).toLocaleDateString().replace(/\./g, '').replace(/\s/g, '-'),
          desc: JSON.parse(element.body)
            .replace(pattern, '')
            .replace(/\*\*/g, '')
            .replace('<br>', '')
            .replace(/\\/g, ''),
        })
      });
      setNewsList(list)
    })
  }, [pageIndex, lang, pageTokenList]);

  // 다음 페이지 토큰이 있는경우에만 이동 가능
  const next = () => {
    if (pageTokenList[pageIndex+1]) {
      let index = pageIndex+1
      setPageIndex(index)
    }
  }

  // 이전 페이지 토큰이 있는경우에만 이동 가능
  const prev = () => {
    // null은 조건문통과를 못하므로 조건 추가
    if (pageTokenList[pageIndex-1] || pageTokenList[pageIndex-1] === null) {
      let index = pageIndex-1
      setPageIndex(index)
    }
  }

  const bannerInfo = {
    title: 'news.mainBannerTitle',
    background: Bg,
    backgroundMobile: MobileBg,
    textColor: 'black',
    size: 'small'
  }
  return (
    <section className="news-container">
      <BannerLayout {...bannerInfo} />
      <div className="news-container__inner">
        <div className="news-list">
        {newsList.map((item, index) => {
          return <NewsLayoutItem key={index} item={item} />
        })}
        </div>
        <div className="pagination">
          <div className="pagination__inner">
            <button className={`prev ${pageIndex === 0 ? 'disabled' : ''}`} onClick={() => prev()}>{t("component.page.prev")}</button>
            <div className="row"></div>
            <button className={`next ${pageTokenList.length === pageIndex+1 ? 'disabled' : ''}`} onClick={() => next()}>{t("component.page.next")}</button>
          </div>
        </div>
      </div>
    </section>
  )
}