import React, { useState, useEffect } from 'react';
import { MenuItem, Select, InputLabel, FormControl, styled as MUIStyled } from "@mui/material";
import SelectCheckImage from 'assets/Icon/select-check.svg';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';


const SymbolMenucomponent = MUIStyled(MenuItem)({
  backgroundColor: 'var(--white)',
  boxShadow: 'none',
  height: '40px',
  fontSize: '14px',
  lineHeight: '14px',
  letterSpacing: '0.16px',
  fontWeigth: '400',
  textAlign: 'left',
  color: 'var(--black)',
  fontFamily: 'Pretendard',
  padding: '10px 16px',
  minHeight: '40px',
  '@media only screen and (max-width: 768px)': {
    minHeight: '32px',
    padding: '7.5px 16px',
  },
  '.no-value': {
    opacity: 0.3,
  },
  '&:hover': {
    'backgroundColor': '#f3f3f3a1',
  },
  '&.Mui-selected': {
    justifyContent: 'space-between',
    posisiton: 'relative',
    background: '#e4e4e4a1',
    '&:hover': {
      backgroundColor: '#dbdbdba1',
    },
    '&::after': {
      position: 'absolute',
      transform: 'translateY(-50%)',
      top: '50%',
      right: '16px',
      width: '20px',
      height: '20px',
      content: '""',
      display: 'block',
      background: `url(${SelectCheckImage}) no-repeat center/contain`,
      filter: 'invert(100%)'
    }
  }
});

function EmptySelect(props) { // 기본값이 없는 select
  const { list, id, label } = props;
  const [valueState, setvalueState] = useState('');

  const handleChange = (event) => {
    setvalueState(event.target.value);
    props.setSelectState(event.target.value);
  };

  return (
    <FormControl className="base-select-wrapper">
      <InputLabel id={id} htmlFor={id}>{label}</InputLabel>
      <Select id={id}
        label={label}
        className="base-select modal-select"
        value={valueState}
        onChange={handleChange}
      >
        <SymbolMenucomponent value="">
          <span className="no-value">{label}</span>
        </SymbolMenucomponent>
        {list.map((item, index) => {
          return <SymbolMenucomponent key={index} value={item}>{item}</SymbolMenucomponent>
        })}
      </Select>
    </FormControl>
  );
}



function test(value) {
  let pathName;
  if (value === 'Terms of Service') {
    pathName = 'TermsofService'
  } else if(value === 'Privacy Policy') {
    pathName = 'PrivacyPolicy'
  } else if(value === 'Notice of Collection of Information') {
    pathName = 'NoticeofCollectionofInformation'
  } else if(value === 'Notice of Right to Opt Out of Sale of Information') {
    pathName = 'NoticeofRighttoOptOutofSaleofInformation'
  } else if(value === 'Data Subjects Request Form') {
    pathName = 'DataSubjectsRequestForm'
  }
  return pathName;
}

function DefaultSelect(props) {
  const { selectState, selectList, id } = props;
  const [valueState, setValueState] = useState(selectState || ''); // Set initial value to selectState
  const navigate = useNavigate();
  const userLanguage = useSelector(state => state.user.language);

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setValueState(selectedValue);
    let pathName = selectedValue;
    let url = `/${userLanguage}`;
    if (pathName) {
      url = url + `/legal/${test(pathName)}`;
    }
    navigate(url);
    props.setSelectState(selectedValue);
  };

  // useEffect를 사용하여 selectState이 변경될 때마다 valueState를 업데이트
  useEffect(() => {
    setValueState(selectState || '');
  }, [selectState]);

  return (
    <Select
      id={id}
      className="base-select"
      value={valueState}
      onChange={handleChange}
    >
      {selectList.map((item, index) => {
        return <SymbolMenucomponent key={index} value={item}>{item}</SymbolMenucomponent>
      })}
    </Select>
  );
}

export default function BasicSelect(props) {
  const { selectState } = props;
  return (
    <>
      {selectState ? <DefaultSelect {...props} /> : <EmptySelect {...props} />}
    </>
  );
}