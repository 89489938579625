import store from "utils/store";
import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import dompurify from "dompurify";
import Bg from "assets/Content/banner/pages/recruit-item.png";
import BaseButton from "components/ui/button/BaseButton"


function ItemBannerLayout() {
  const { t } = useTranslation()
  const sanitizer = dompurify.sanitize;
  return (
    <section className={`item-banner-layout`}>
      <div className="item-banner-layout-inner">
        <img className="background-item" src={Bg} alt=""/>
        <p className={`banner-title-text`}>{t("recruit.banner.title")}</p>
        <span className={`banner-subtitle-text`}
         dangerouslySetInnerHTML={{ __html: sanitizer(t("recruit.banner.subTitle")) }}></span>
      </div>
    </section>
  )
}

export default function Recruit() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const lang = useSelector(state=>{
    return state.user.language;
  });
  const detailNavigation = (id) => {
    let url = `/${lang}/recruit`;
    if (id) {
      url = url + `/${id}`;
    }
    navigate(url, { state: "recruit" });
  };

  useEffect(() => {
    const loacle = lang !== 'ko' && lang ? `_${lang}` : '';
    const content_id = '채용'+ loacle;
    store.getBoardsByOrder(content_id, "DESC")
    .then(data => {
      let list = []
      data.forEach(element => {
        list.push({
          id: element.id,
          desc: JSON.parse(element.body),
          date: new Date(element.create_date).toLocaleDateString().replace(/\./g, '').replace(/\s/g, '-'),
          title: element.title,
        })
      });
      setData(list);
    })
  }, [lang]);

  return (
    <section className="recruit-container">
      <ItemBannerLayout />
      <div className="recruit-container__inner">
        <div className="recruit-list">
          {data.map((item, index) => {
            return <div key={index} className="recruit-list-item">
              <div className="recruit-list-item__info">
                <p className="position">{item.title}</p>
                {item.desc.position && <span itemRef="item.desc.position" className="location">{item.desc.position}</span>}
              </div>
              <BaseButton onClick={() => detailNavigation(item.id)} label="Apply Now" color="black" />
            </div>
          })}
        </div>
      </div>
    </section>
  )
}