import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import store from "utils/store";
import BannerLayout from "components/ui/banner/BannerLayout"
import Bg from "assets/Content/banner/pages/games.png";
import MobileBg from "assets/Content/banner/pages/games-m.png";

const tabs = [
  {
    id: 'allGames',
    name: 'All Games',
  },
  {
    id: 'casualSlot',
    name: 'Casual Slot',
  },
  {
    id: 'casualGame',
    name: 'Casual Game',
  },
  {
    id: 'upcoming',
    name: 'Upcoming',
  },
]

export function TabsLayout() {
  const [data, setData] = useState([]);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const lang = useSelector(state=>{
    return state.user.language;
  });
  const navigate = useNavigate();
  useEffect(() => {
    const loacle = lang !== 'ko' && lang ? `_${lang}` : '';
    const content_id = 'GAMES'+ loacle;
    store.getBoardsByOrder(content_id, "DESC")
    .then(data => {
      let list = []
      data.forEach(element => {
        list.push({
          id: element.id,
          image: store.awsFilePath(element.thumbnail),
          title: element.title,
          date: new Date(element.create_date).toLocaleDateString().replace(/\./g, '').replace(/\s/g, '-'),
          desc: JSON.parse(element.body),
        })
      });
      setData(list)
    })
  }, [lang]);

  const checkDetail = (item) => {
    return item.id && item.desc.category !== 'Upcoming' && item.desc.gameIcon
  }

  const detailNavigation = (item) => {
    if (checkDetail(item)) {
      let url = `/${lang}/games`;
      if (item.id) {
        url = url + `/${item.id}`;
      }
      navigate(url);
    }
  };

  return (
    <div className="tabs-layout">
      <nav className="tab-header">
        <div className="tab-header__inner">
          { tabs.map((tab, index) => {
            return <div className={`tab-header-item ${selectedTab === tab ? 'isActive' : ''}`}
            key={index} onClick={() => setSelectedTab(tab)}><p>{tab.name}</p></div>
          }) }
        </div>
      </nav>
      <div className="tab-body games">
        {data.map((item, index) => {
          if (selectedTab.name === item.desc.category || selectedTab.id === 'allGames') {
            return (
              <div className="game-item" key={index}>
                <div className={`game-item__inner ${!checkDetail(item) && 'noPointer'}`} onClick={() => detailNavigation(item)}>
                  <img className="thumbnail" src={item.image} alt={item.title} />
                  <div className="info-text">
                    <p className="name">{item.title}</p>
                    <span className="type">{item.desc.category}</span>
                  </div>
                </div>
                <ul className="game-item__link">
                  {item.desc.store.google && <li onClick={() => {window.open(item.desc.store.google)}} className={`link-item google`}></li>}
                  {item.desc.store.apple && <li onClick={() => {window.open(item.desc.store.apple)}} className={`link-item apple`}></li>}
                  {item.desc.store.facebook && <li onClick={() => {window.open(item.desc.store.facebook)}} className={`link-item facebook`}></li>}
                  {item.desc.store.apk && <li onClick={() => {window.open(item.desc.store.apk)}} className={`link-item apk`}></li>}
                  {item.desc.store.web && <li onClick={() => {window.open(item.desc.store.web)}} className={`link-item website`}></li>}
                </ul>
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
}



export default function Games() {
  const bannerInfo = {
    title: 'games.mainBannerTitle',
    background: Bg,
    backgroundMobile: MobileBg,
    textColor: 'white'
  }
  return (
    <div className="games-container">
      <BannerLayout {...bannerInfo} />
      <div className="games-inner">
        <TabsLayout />
      </div>
    </div>
  )
}