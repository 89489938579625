import { useParams } from 'react-router-dom';
import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SET_LANGUAGE } from 'store/modules/user';
import i18n from "./locales/i18n";
import Footer from './components/common/Footer';
import Header from './components/common/Header';
import { Amplify, AuthModeStrategyType } from 'aws-amplify';
import awsExports from './aws-exports';
import ErrorPage from "./pages/Error";
import CookieBanner from 'components/common/CookieBanner';

import AuditionModal from "components/modal/Audition";
import NoticeModal from "components/modal/Notice";

Amplify.configure(awsExports);
Amplify.configure({ ...awsExports,
    DataStore: {
      authModeStrategyType: AuthModeStrategyType.MULTI_AUTH
    }
})

const Layout = () => {

  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [infoModal2Open, setInfoModal2Open] = useState(false);

  const { language } = useParams();
  const dispatch = useDispatch();
  const userLanguage = useSelector(state => {
    return state?.user?.language;
  });

  useEffect(() => {
    if (language === 'ko') {
      setInfoModalOpen(true);
    }
    // if (language === 'ko' || language === 'en') {
    //   setInfoModal2Open(true);
    // }
  }, []);

  useEffect(() => {
    if (language === 'ko' || language === 'en' || language === 'cn') {
      i18n.changeLanguage(language);
      dispatch(SET_LANGUAGE(language));
    }
  }, [language, dispatch]);

  if (!userLanguage) {
    return null;
  }
  const modalClose = () => {
    setInfoModalOpen(false)
  };
  const modalClose2 = () => {
    setInfoModal2Open(false)
  };


  return (
    <div id="App">
      <div className="wrapper" id="scrollbar">
        <Header />
        <main className="container">
          {
            language === 'ko' || language === 'en' || language === 'cn' ?
            (<Outlet />) : (<ErrorPage />)
          }
          </main>
        <CookieBanner />
        <Footer />
        {infoModalOpen &&
          <AuditionModal open={infoModalOpen} handleClose={modalClose} />
        }
        {infoModal2Open &&
          <NoticeModal open={infoModal2Open} handleClose={modalClose2} />
        }
      </div>
    </div>
  );
}

export default Layout;