import Dialog from '@mui/material/Dialog';
import AuditionImg from "assets/Content/banner/pages/ghoststudio-audition.png";
import AuditionMobileImg from "assets/Content/banner/pages/ghoststudio-audition-m.png";
import { useTranslation } from 'react-i18next'
import dompurify from "dompurify";


export default function Audition(props) {
  const { t } = useTranslation()
  const sanitizer = dompurify.sanitize;
  const { open, handleClose } = props;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="base-dialog-wrapper info-dialog-wrapper"
    >
      <div className="base-dialog-container">
        <button onClick={handleClose} className="close-btn"></button>
        <div className="base-dialog-inner">
          <div className="info-head">
            <p className="info-head__main">{t("Popup.notice.title")}</p>
          </div>
          <div className="info-body">
            <div className="info-inner">
              <div className="info-item">
                <p className="info-item__subTitle" dangerouslySetInnerHTML={{ __html: sanitizer(t("Popup.notice.body")) }}></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}