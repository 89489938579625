import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import store from "utils/store";
import BannerLayout from "components/ui/banner/BannerLayout"
import GhoststudioBg from "assets/Content/banner/pages/ghoststudio.png";
import GhoststudioMobileBg from "assets/Content/banner/pages/ghoststudio-m.png";
import MaskGame from "assets/Content/ghoststudio/game.png";
import MaskWebtoon from "assets/Content/ghoststudio/webtoon.png";
import MaskDrama from "assets/Content/ghoststudio/drama.png";
import { useTranslation } from 'react-i18next'
import dompurify from "dompurify";
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';


function HistoryLayout() {
  const [data, setData] = useState([]);
  const { t } = useTranslation()
  const lang = useSelector(state=>{
    return state.user?.language;
  });

  useEffect(() => {
    const loacle = lang !== 'ko' && lang ? `_${lang}` : '';
    const content_id = '연혁'+ loacle;
    store.getBoards(content_id)
    .then(data => {
      let list = []
      data.forEach(element => {
        list.push({
          desc: JSON.parse(element.body),
        })
      });
      setData(list[0].desc)
    })
  }, [lang]);

  return (
    <section id="history" className="section-layout">
      <div className="section-layout__title">
        <p>{t("ghoststudio.history.title")}</p>
      </div>
      <div className="section-layout__inner history">
        <ul className="history-list-wrapper">
          {data.map((item, index) => {
            return <li key={index} className="history-list-item">
              <p className="date">{item.date}</p>
              <p className="desc">{item.context}</p>
            </li>
          })}
        </ul>
      </div>
    </section>
  )
}


const MyComponent = ({cooldicate}) => {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth <= 768);
  const [center, setCneter] = useState({});
  const [position, setPosition] = useState({});
  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const parts = cooldicate?.split(/[, ]/);
    if (cooldicate) {
      setCneter({
        lat: Number(parts?.[0]), // 위도
        lng: Number(parts?.[2]), // 경도
      })
      setPosition({
        lat: Number(parts?.[0]), // 위도
        lng: Number(parts?.[2]), // 경도
      })
    }
  }, [cooldicate]);

  const containerStyle = {
    width: "100%",
    height: isWideScreen ? '250px' : '400px',
  };
  // const markerIcon = (
  //   <div style={{ width: 50, height: 50, background: "red" }}></div>
  // );
  // const onLoad = (map) => {
  //   // Do something on map load
  // };
  // const onUnmount = (map) => {
  //   // Do something when the map is unmounted
  // };
  // const onLoad2 = (marker) => {
  //   // Do something when the marker is loaded
  // };


  return (
    <LoadScript
      googleMapsApiKey="AIzaSyAgQtlK6Z3nNtRho2uglYp4dS0z83i0lCU"
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={17}
        // onLoad={onLoad}
        // onUnmount={onUnmount}
      >
        <Marker
          // onLoad={onLoad2}
          position={position}
          label={""}
          // icon={markerIcon}
        />
        <></>
      </GoogleMap>
    </LoadScript>
  );
};


function OfficeLayout() {
  const [data, setData] = useState([]);
  const { t } = useTranslation()
  const lang = useSelector(state=>{
    return state.user?.language;
  });

  useEffect(() => {
    const loacle = lang !== 'ko' && lang ? `_${lang}` : '';
    const content_id = '회사정보'+ loacle;
    store.getBoards(content_id)
    .then(data => {
      let list = []
      data.forEach(element => {
        list.push({
          desc: JSON.parse(element.body),
        })
      });
      setData(list[0].desc)
    })
  }, [lang]);

  return (
    <>
      {data &&
    <section id="office" className="section-layout">
    <div className="section-layout__title">
      <p>{t("ghoststudio.office.title")}</p>
    </div>
    <div className="section-layout__inner office">
        <div className="office-info">
          <div className="map-wrapper">
          {data?.items?.[lang ==='ko'? 0 : lang === 'en' ? 1 : lang === 'cn' ? 2 : 0]?.cooldicate && <MyComponent cooldicate={data?.items?.[lang ==='ko'? 0 : lang === 'en' ? 1 : lang === 'cn' ? 2 : 0]?.cooldicate} />}
          </div>
          <ul className="call-list">
            <li>
              <p>{t(`ghoststudio.office.call.main`)}</p>
              <span>{data.phone}</span>
            </li>
            <li>
              <p>{t(`ghoststudio.office.call.ir`)}</p>
              <span>{data.irRequest}</span>
            </li>
            {data.entRequest &&
            <li>
              <p>{t(`ghoststudio.office.call.entertainmentInquiries`)}</p>
              <span>{data.entRequest}</span>
            </li>}
          </ul>
          <ul className="address-info">
            {data?.items?.map((item, index) => {
              return <li key={index}>
                <p>{item.name}</p>
                <span>{item.position}</span>
              </li>
            })}
          </ul>
        </div>
    </div>
  </section>}
    </>
  )
}

export default function GhostStudio() {
  const { t } = useTranslation()
  const sanitizer = dompurify.sanitize;
  const bannerInfo = {
    title: 'ghoststudio.mainBannerTitle',
    background: GhoststudioBg,
    backgroundMobile: GhoststudioMobileBg,
    textColor: 'white'
  }
  return (
    <div className="ghoststudio-container">
      <BannerLayout {...bannerInfo} />
      <div className="ghoststudio-inner">
        <section id="company" className="section-layout">
          <div className="section-layout__title">
            <p>{t("ghoststudio.about.title")}</p>
            <span>{t("ghoststudio.about.subTitle")}</span>
          </div>
          <div className="section-layout__inner about">
            <div className="content-image">
              <div className="content-image-item">
                <div className="image-wrapper">
                  <img src={MaskGame} alt="GAME img" />
                </div>
                <p>GAME</p>
              </div>
              <div className="content-image-item">
                <div className="image-wrapper">
                  <img src={MaskWebtoon} alt="WEBTOON img" />
                </div>
                <p>WEBTOON</p>
              </div>
              <div className="content-image-item">
                <div className="image-wrapper">
                  <img src={MaskDrama} alt="DRAMA + MOVIE img" />
                </div>
                <p>DRAMA <br className="m-break" />+ MOVIE</p>
              </div>
            </div>
            <p className="information-desc" dangerouslySetInnerHTML={{ __html: sanitizer(t("ghoststudio.about.desc")) }}></p>
          </div>
        </section>
        <section className="image-banner"></section>
        <HistoryLayout />
        <OfficeLayout />
      </div>
    </div>
  )
}