import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import store from "../utils/store";
import Viewer from '@toast-ui/editor/dist/toastui-editor-viewer';
import '@toast-ui/editor/dist/toastui-editor-viewer.css';
import BaseButton from "components/ui/button/BaseButton"
import { useTranslation } from 'react-i18next'

export default function RecruitDetail(props) {
  const [data, setData] = useState({})
  const { state } = props;
  const { index } = useParams();
  const navigate = useNavigate();
  const lang = useSelector(state=>{
    return state.user.language;
  });
  const { t } = useTranslation()

  const goList = () => {
    let url = `/${lang}/${state}`;
    navigate(url, { state: state });
  };

  useEffect(()=>{
    store.getPost(index)
    .then((data)=>{
      data.create_date = new Date(data?.create_date).toLocaleDateString().replace(/\./g, '').replace(/\s/g, '-')
      data.body = JSON.parse(data.body)
      setData(data)
      new Viewer({
        el: document.querySelector('#viewer'),
        initialValue: data.body.text
      });
    })
  }, [])

  return (
    <div className="recruit-detail-container">
      <div className="recruit-detail-inner">
        <div className="content-layout">
          <button className="list-btn" onClick={() => goList()}><p>{t("details.back")}</p></button>
          <div className="content-layout__inner">
            <div className="content-layout__inner--head">
                <p className="position">{data?.title}</p>
                <span className="location">{data?.body?.position}</span>
            </div>
            <div className="content-layout__inner--body">
              <div id="viewer"></div>
            </div>
          </div>
          <BaseButton onClick={() => {window.open('https://maimai.cn/company?webcid=131nVkNnL')}} label="Apply Now" color="black" />
        </div>
      </div>
    </div>
  )
}