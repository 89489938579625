import React from 'react';

class ExternalHTMLRenderer extends React.Component {
  constructor(props) {
    super(props);
    this.externalHTML = `<div class="wrap">
    <div class="disTit tal">
      <h1>Notice of Right to Opt Out of Sale of Information</h1>
    </div>
    <div class="artContent mt40">

<p style="text-align: left; font-size: 14px; font-family: DengXian; white-space: normal; text-indent: 28px;"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;"><strong>Notice of Right to Opt Out of Sale of Information</strong></span></p>
<p class="MsoListParagraph" style="text-align: left; text-indent: 28px; font-size: 14px; font-family: DengXian; white-space: normal;"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;">(FOR CALIFORNIA RESIDENTS ONLY)</span></p>
<p class="MsoListParagraph" style="text-align: left; text-indent: 28px; font-size: 14px; font-family: DengXian; white-space: normal;"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;">Lastly Updated Oct 16, 2023</span></p>
<p class="MsoListParagraph" style="text-align: left; text-indent: 28px; font-size: 14px; font-family: DengXian; white-space: normal;"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;">&nbsp;</span></p>
<p class="MsoListParagraph" style="text-align: left; text-indent: 28px; font-size: 14px; font-family: DengXian; white-space: normal;"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;">Your privacy is important to us.</span></p>
<p class="MsoListParagraph" style="margin: 0px 0px 0px 28px; text-align: left; text-indent: 28px; font-size: 14px; font-family: DengXian; white-space: normal;"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;">&nbsp;</span></p>
<p class="MsoListParagraph" style="margin: 0px 0px 0px 28px; text-align: left; text-indent: 28px; font-size: 14px; font-family: DengXian; white-space: normal;"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;">If you are a California resident, starting January 1, 2020, you have a right to opt out of the sale of your personal information, as defined in the California Consumer Privacy Act of 2018, Civil Code §1798.100 et seq. (CCPA).&nbsp;&nbsp;This Notice is to provide you with information regarding this opt-out right. All terms are used in this Notice as defined in the CCPA or applicable regulations thereunder.</span></p>
<p class="MsoListParagraph" style="margin: 0px 0px 0px 28px; text-align: left; text-indent: 28px; font-size: 14px; font-family: DengXian; white-space: normal;"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;"><br></span></p>
<p class="MsoListParagraph" style="margin: 0px 0px 0px 28px; text-align: left; text-indent: 28px; font-size: 14px; font-family: DengXian; white-space: normal;"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;">Under the CCPA, subject to certain exceptions and limitations, the “sale” of personal information may include any communication of personal information to any third party for monetary or other valuable consideration.</span></p>
<p class="MsoListParagraph" style="margin: 0px 0px 0px 28px; text-align: left; text-indent: 28px; font-size: 14px; font-family: DengXian; white-space: normal;"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;"><br></span></p>
<p class="MsoListParagraph" style="margin: 0px 0px 0px 28px; text-align: left; text-indent: 28px; font-size: 14px; font-family: DengXian; white-space: normal;"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;">You may exercise your opt-out right by submitting your request by email at&nbsp;<span style="font-size: 14px; font-family: arial, helvetica, sans-serif; text-decoration: underline;">Service@ghoststudio.net</span></span><span style="font-family: arial, helvetica, sans-serif; font-size: 14px; text-decoration: none;">.</span><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;">You may also submit an opt-out request by submitting the&nbsp;&nbsp;<span style="font-family: sans-serif; font-size: 16px;">“</span></span><a href="DataSubjectsRequestForm" target="_self" style="color: rgb(0, 0, 0); text-decoration: underline;"><span style="color: rgb(0, 0, 0);"><strong><span style="font-family: arial, helvetica, sans-serif; font-size: 14px; color: rgb(0, 0, 0);">Data Subjects Request Form</span></strong></span></a><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;">”.</span></p>
<p class="MsoListParagraph" style="margin: 0px 0px 0px 28px; text-align: left; text-indent: 28px; font-size: 14px; font-family: DengXian; white-space: normal;"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;"><br></span></p>
<p class="MsoListParagraph" style="margin: 0px 0px 0px 28px; text-align: left; text-indent: 28px; font-size: 14px; font-family: DengXian; white-space: normal;"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;">You may use an authorized agent to exercise your right to opt-out, in which event the authorized agent’s request to opt out on your behalf sent by mail shall include a power of attorney or a notarized statement properly signed by you appointing such authorized agent for such purpose. If your authorized agent submits the opt-out request online via email address provided above, a copy of the power of attorney or the original the notarized statement shall be scanned to the email address specified above within five (5) days after submitting such online request, or we may in our discretion otherwise verify the agent’s authority to our reasonable satisfaction.</span></p>
<p class="MsoListParagraph" style="margin: 0px 0px 0px 28px; text-align: left; text-indent: 28px; font-size: 14px; font-family: DengXian; white-space: normal;"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;"><br></span></p>
<p class="MsoListParagraph" style="margin: 0px 0px 0px 28px; text-align: left; text-indent: 28px; font-size: 14px; font-family: DengXian; white-space: normal;"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;">For additional information, please see our </span><a href="PrivacyPolicy" target="_self" style="font-family: arial, helvetica, sans-serif; font-size: 14px; text-decoration: underline; color: rgb(0, 0, 0);"><span style="font-family: arial, helvetica, sans-serif; font-size: 14px; text-decoration: underline; color: rgb(0, 0, 0);"><strong>Privacy Policy</strong></span></a><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;">. If you have any further questions regarding this Notice, please contact us at </span><span style="font-family: arial, helvetica, sans-serif; font-size: 14px; text-decoration: underline;">Service@ghoststudio.net</span><span style="font-family: arial, helvetica, sans-serif; font-size: 14px;">.</span></p>
    </div>
  </div>
    `
  }

  render() {
    return <div dangerouslySetInnerHTML={{ __html: this.externalHTML }} />;
  }
}

export default ExternalHTMLRenderer;