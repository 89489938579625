import Dialog from '@mui/material/Dialog';
import AuditionImg from "assets/Content/banner/pages/ghoststudio-audition.png";
import AuditionMobileImg from "assets/Content/banner/pages/ghoststudio-audition-m.png";


export default function Audition(props) {
  const { open, handleClose } = props;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="base-dialog-wrapper info-dialog-wrapper"
    >
      <div className="base-dialog-container">
        <button onClick={handleClose} className="close-btn"></button>
        <div className="base-dialog-inner">
          <div className="info-head">
            <p className="info-head__main">WHO IS THE NEXT GHOST!</p>
            <p className="info-head__sub">고스트스튜디오는 체계적인 육성관리로 신인배우 발굴에 앞장서고 있습니다.<br />
                재능과 열정이 가득한 아티스트를 기다립니다.</p>
          </div>
          <div className="info-body">
            <img src={AuditionImg} className="desktop" alt="ghoststudio audition banner img" />
            <img src={AuditionMobileImg} className="mobile" alt="ghoststudio audition banner img" />
            <div className="info-inner">
              <div className="info-item">
                <p className="info-item__title">지원 자격</p>
                <p className="info-item__subTitle">배우를 지망하는 남녀 누구나 상시 접수</p>
              </div>
              <div className="info-item">
                <p className="info-item__title">지원방법</p>
                <p className="info-item__subTitle">홈페이지 접수 또는 이메일 접수</p>
                <ul className="info-item__order">
                  <li>1. 홈페이지 접수 (Audition 페이지 추가 예정)</li>
                  <li>2. E-MAIL 접수 | audition@ghoststudio.co.kr</li>
                </ul>
                <ul className="info-item__list">
                  <li><span>•</span> 프로필 첨부</li>
                  <li><span>•</span> 사진 첨부 (정면, 좌축, 우측, 전신)</li>
                </ul>
                <div className="info-item__reference">
                  <p>*프로필과 사진을 압축하여 첨부해주세요.</p>
                  <span>폴더명: 이름_성별_년생으로 변경 <br className="break" />ex)홍길동_남_04</span>
                </div>
              </div>
              <div className="info-item">
                <p className="info-item__title">주의사항</p>
                <ul className="info-item__list">
                  <li><span>•</span> 과하게 보정된 사진 사용을 지양해주세요.</li>
                  <li><span>•</span> 오디션 합격 여부에 관한 전화문의는 따로 받지 않습니다.</li>
                  <li><span>•</span> 오디션 지원시 접수된 서류 및 오디션 관련 자료는 반환 되지 않습니다.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}