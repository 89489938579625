import React from 'react';
import formcss from './form.module.css'

class ExternalHTMLRenderer extends React.Component {
  dataValue = {
    userID: "",
    email: "",
    country: "",
    specifyTheRequest: {},
    varifyFlag: false,
  }
  
  submit = () => {
    if (this.dataValue.userID === "") {
      alert("User ID Required")
      return
    }
    if (this.dataValue.country === "") {
      alert("State of Residence Required")
      return
    }
    if (
      !this.dataValue.specifyTheRequest["3845"] &&
      !this.dataValue.specifyTheRequest["3846"] &&
      !this.dataValue.specifyTheRequest["3847"] &&
      !this.dataValue.specifyTheRequest["3848"] &&
      !this.dataValue.specifyTheRequest["3849"]
      ) {
        alert("Specify the request(s) Required")
        return
    }
    window.location.href = this.mailform;
  }

  varify = () => {
    this.mailform = ""
    if (this.dataValue.userID === "") {
      this.dataValue.varifyFlag = false
      return false
    }
    if (this.dataValue.country === "") {
      this.dataValue.varifyFlag = false
      return false
    }
    if (
      !this.dataValue.specifyTheRequest["3845"] &&
      !this.dataValue.specifyTheRequest["3846"] &&
      !this.dataValue.specifyTheRequest["3847"] &&
      !this.dataValue.specifyTheRequest["3848"] &&
      !this.dataValue.specifyTheRequest["3849"]
      ) {
        this.dataValue.varifyFlag = false
        return false
    }
    this.mailform = `mailto:Service@ghoststudio.net?subject=[GHOSTSTUDIO] Data Subjects Request Form&body=
1. Consumer information%0D%0A
User ID: ${this.dataValue.userID}%0D%0A
Email Address:  ${this.dataValue.email}%0D%0A
State of Residence:  ${this.dataValue.country}%0D%0A
%0D%0A
2. Specify the request(s) - check all that apply%0D%0A
${this.dataValue.specifyTheRequest["3848"]?`✅`:`❌`} Request to opt out of the “sale” of personal information about the consumer.%0D%0A
${this.dataValue.specifyTheRequest["3847"]?`✅`:`❌`} Request to delete personal information GHOSTSTUDIO has collected from the consumer.%0D%0A
${this.dataValue.specifyTheRequest["3846"]?`✅`:`❌`} Request to obtain specific pieces of personal information GHOSTSTUDIO collected about the consumer.%0D%0A
${this.dataValue.specifyTheRequest["3845"]?`✅`:`❌`} Request to know Request to know categories of personal information GHOSTSTUDIO has collected, used, disclosed and/or sold about the consumer.%0D%0A
${this.dataValue.specifyTheRequest["3849"]?`✅`:`❌`} Other GDPR rights (for details please see our Privacy Policy Section 9)%0D%0A
`
    this.dataValue.varifyFlag = true
    console.log("this.mailform", this.dataValue.varifyFlag)
  }
  mailform = ""

  render() {
    return <div className={formcss.wrap}>
      <div>
        <h1 className={formcss.h1}>Data Subjects Request Form</h1>
        <p className={formcss.title}>Data Subjects Request Form</p>
        <h4 className={formcss.h4}>1. Consumer information</h4>
        <div>
          <div className={formcss.iptLab}>User ID: (required)</div>
          <div className={formcss.iptCtrl}><input onChange={(e) => { this.dataValue.userID = e.target.value; this.varify(); }} type="text" placeholder="Please enter User ID" /></div>
        </div>
        <div>
          <div className={formcss.iptLab}>Email Address: (recommended)</div>
          <div className={formcss.iptCtrl}><input onChange={(e) => { this.dataValue.email = e.target.value; this.varify(); }} type="text" placeholder="Please enter Email Address" /></div>
        </div>
        <div>
          <div className={formcss.iptLab}>State of Residence: (required)</div>
          <div className={formcss.iptCtrl}><input onChange={(e) => { this.dataValue.country = e.target.value; this.varify(); }} type="text" placeholder="Please enter State of Residence" /></div>
        </div>
        <div className={formcss.artMore}>
          <p>We may ask for additional information or documents to verify the consumer’s identity. The information provided by the consumer will be used only to respond to consumer’s request, including verifying identity and keeping records.</p><br/>
          <p>
            You may use an authorized agent to submit a request to opt-out on your behalf if you provide the authorized agent written permission to do so. If this is the case, please provide us with a copy of the said permission by sending it to
            <a href="http://mailto:Service@ghoststudio.net" target="_self">
              <span> Service@ghoststudio.net</span>
            </a>
            <span>.</span>
            We may deny a request from an authorized agent that does not submit proof that they have been authorized by you to act on your behalf.
          </p>
        </div>
        <h4 className={formcss.h4}>2. Specify the request(s) – check all that apply</h4>
        <div>
          <div className={formcss.onCheck}>
            <input 
              onChange={(e) => { this.dataValue.specifyTheRequest[e.target.value] = e.target.checked; this.varify(); }} 
              checked={this.dataValue.specifyTheRequest["3848"]} 
              value="3848" type="checkbox" id="strp1" />
            <label htmlFor="strp1">Request to opt out of the “sale” of personal information about the consumer.</label>
          </div>
          <div className={formcss.onCheck}>
            <input 
              onChange={(e) => { this.dataValue.specifyTheRequest[e.target.value] = e.target.checked; this.varify(); }} 
              checked={this.dataValue.specifyTheRequest["3847"]} 
              value="3847" type="checkbox" id="strp2" />
            <label htmlFor="strp2"> Request to delete personal information GHOSTSTUDIO has collected from the consumer.</label>
          </div>
          <div className={formcss.onCheck}>
            <input 
              onChange={(e) => { this.dataValue.specifyTheRequest[e.target.value] = e.target.checked; this.varify(); }} 
              checked={this.dataValue.specifyTheRequest["3846"]} 
              value="3846" type="checkbox" id="strp3" />
            <label htmlFor="strp3"> Request to obtain specific pieces of personal information GHOSTSTUDIO collected about the consumer.</label>
          </div>
          <div className={formcss.onCheck}>
            <input 
              onChange={(e) => { this.dataValue.specifyTheRequest[e.target.value] = e.target.checked; this.varify(); }} 
              checked={this.dataValue.specifyTheRequest["3845"]} 
              value="3845" type="checkbox" id="strp4" />
            <label htmlFor="strp4">Request to know Request to know categories of personal information GHOSTSTUDIO has collected, used, disclosed and/or sold about the consumer.</label>
          </div>
          <div className={formcss.onCheck}>
            <input 
              onChange={(e) => { this.dataValue.specifyTheRequest[e.target.value] = e.target.checked; this.varify(); }} 
              checked={this.dataValue.specifyTheRequest["3849"]} 
              value="3849" type="checkbox" id="strp5" />
            <label htmlFor="strp5">Other GDPR rights (for details please see our <a href="PrivacyPolicy">Privacy Policy Section 9</a>)</label>
          </div>
        </div>
        <h4 className={formcss.h4}>3. Declaration</h4>
        <p className={formcss.artMore}>By submitting this form, I hereby certify that the information entered into this form is complete, accurate and up-to-date and that I am the consumer who is the subject of the request, as indicated above.</p>
        <div>
          <button onClick={this.submit} className={formcss.iptButton}>Submit</button>
        </div>
      </div>
    </div>
  }
}

export default ExternalHTMLRenderer;